import MemberList from "@/components/members/MemberList.vue";
import { createRouter, createWebHistory } from "vue-router";
import AdminDashboard from "@/components/dashboard/AdminDashboard.vue";
import ChangePassword from "@/components/members/ChangePassword.vue";
import EditMember from "@/components/members/EditMember.vue";
import MemberDetails from "@/components/members/MemberDetails.vue";
import AddMember from "@/components/members/AddNewMember.vue";
import PaidPayments from "@/components/payments/PaidPayments.vue";
import LoyaltyBonus from "@/components/payments/LoyaltyBonus.vue";
import SponserBonus from "@/components/payments/SponserBonus.vue";
import Login from "@/components/login/LoginPage.vue";
import MemberDashboard from "@/components/members/MemberDashboard.vue";
import BroadcastMessage from "@/components/BroadcastMessage.vue";
import AnnouncementsList from "@/components/AnnouncementsList.vue";
import TransferCredit from "@/components/credits/TransferCredit.vue";
import IssueCredit from "@/components/credits/IssueCredit.vue";

import AvailableCredits from "@/components/credits/AvailableCredits.vue";
import CreditHistory from "@/components/credits/CreditHistory.vue";
import UnpaidPayments from "@/components/payments/UnpaidPayments.vue";
import PaginationPage from "@/components/PaginationPage.vue";
// import sessionManagementService from "@/SessionManagementService";

//sponser
import PaidSponser from "@/components/payments/PaidSponser.vue";
import UnpaidSponser from "@/components/payments/UnpaidSponser.vue";


const routesUrl = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AdminDashboard,
  },

  {
    path: "/announcements",
    name: "Announcements",
    component: BroadcastMessage,
  },
  {
    path: "/announcements-list",
    name: "AnnouncementsList",
    component: AnnouncementsList,
  },

  {
    path: "/available-credits",
    name: "AvailableCredits",
    component: AvailableCredits,
  },

  {
    path: "/credits-history/:id?",
    name: "CreditHistory",
    component: CreditHistory,
  },
  {
    path: "/change-password/",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/edit-member",
    name: "EditMember",
    component: EditMember,
  },

  {
    path: "/transfer-credit",
    name: "TransferCredit",
    component: TransferCredit,
  },
  {
    path: "/issue-credit",
    name: "IssueCredit",
    component: IssueCredit,
  },
  {
    path: "/member-dashboard",
    name: "MemberDashboard",
    component: MemberDashboard,
  },
  {
    path: "/member-details",
    name: "MemberDetails",
    component: MemberDetails,
  },
  // {
  //   path: "/member-details",
  //   name: "Member Details",
  //   component: MemberDetails,
  // },
  {
    path: "/register-new-member",
    name: "Add Member",
    component: AddMember,
  },
  {
    path: "/members-list",
    name: "list",
    component: MemberList,
  },
  {
    path: "/unpaid-payments",
    name: "Unpaid Payments",
    component: UnpaidPayments,
  },
  {
    path: "/level-bonus",
    name: "Level Bonus",
    component: LoyaltyBonus,
  },
  {
    path: "/sponser-bonus",
    name: "Sponser Bonus",
    component: SponserBonus,
  },
  {
    path: "/paid-payments",
    name: "Paid Payments",
    component: PaidPayments,
  },
  {
    path: "/pagination",
    name: "PaginationPage",
    component: PaginationPage,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
 
  //sponser
  {
    path: "/paid-sponser",
    name: "PaidSponser",
    component: PaidSponser,
  },
  {
    path: "/unpaid-sponser",
    name: "UnpaidSponser",
    component: UnpaidSponser,
  },

];
const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes: routesUrl,
});

// const TIMEOUT_DURATION = 1 * 60 * 1000;

// // Timeout variable to store the timeout reference
// let timeout;

// // Reset the timeout function
// const resetTimeout = () => {
//   clearTimeout(timeout);
//   timeout = setTimeout(logout, TIMEOUT_DURATION);
// };

// // Function to logout the user
// const logout = () => {
//   // Clear user authentication data
//   localStorage.removeItem("userData");
//   // Redirect to login page

//   router.push({ name: "Login" });
// };

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !localStorage.getItem("member_id")) {
    next({ name: "Login" }); // Redirect to login page if not authenticated
  } else {
    next(); // Proceed with the navigation
  }
  // resetTimeout();
  // next();
});

// Start the timeout on application startup
// resetTimeout();

export default router;
