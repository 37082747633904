<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <h2 class="h4 border-bottom pb-3">Available Credits  <span class="badge rounded-pill bg-primary total">
        {{ paginationData.total }}</span
      ></h2>
      <div class="mt-4">
        <div class="display-flex mb-2">
          <!-- <div class="flex-1">
            <ul class="level-tabs">
              <li>
                <span
                  class="badge rounded-pill"
                  @click="activeTab = 'cr'"
                  :class="{
                    'active-level': activeTab === 'cr',
                    'bg-secondary': activeTab !== 'cr',
                  }"
                  >Unused Credits</span
                >
              </li>
              <li>
                <span
                  class="badge rounded-pill"
                  @click="activeTab = 'dr'"
                  :class="{
                    'active-level': activeTab === 'dr',
                    'bg-secondary': activeTab !== 'dr',
                  }"
                  >Transferred Credits</span
                >
              </li>
            </ul>
          </div> -->
          <div>
            <input type="search" class="search-form-control" placeholder="Search..." 
            @input="debouncedSearch"
            v-model="searchQuery" />
            <button class="export-btn ms-3" @click="exportAsPdf">
              Export as Excel <span class="material-icons"> print </span>
            </button>
          </div>
        </div>
        <div v-if="msg" class="alert alert-success">
          {{ msg }}
        </div>
        <div class="table-sort">

          <table class="table" id="pdf-table">
            <thead>
              <tr>
                <th scope="col" @click="sortUsers('user_id')">
                  Member ID <span class="material-icons">swap_vert</span>
                </th>
                <th scope="col" @click="sortUsers('name')">Member Name</th>

                <th scope="col" @click="sortUsers('credits')">
                  Available Credits <span class="material-icons">swap_vert</span>
                </th>
                <!-- <th scope="col">Status</th> -->
                <!-- <th scope="col">
                  Issue Date <span class="material-icons">swap_vert</span>
                </th> -->
                <th scope="col" v-if="activeTab === 'dr'">Transfer to</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <!-- {{
              users
            }} -->
            <tbody>
              <!-- <tr v-if="filteredUsers.length === 0"> -->
              <!-- <tr v-if="creditsList.length === 0">
                <td colspan="7" class="text-center" >
                  No records match your criteria.{{ creditsList.length }}
                </td>
              </tr> -->
              <!-- <tr v-else v-for="user in filteredUsers" :key="user.member_id"> -->

              <tr v-for="user in creditsList" :key="user.member_id">
                <td>{{ user.member_id }}</td>
                <td>
                  <div class="row cursor-pointer" @click="getUserDetails(user.member_id)">
                    <div class="profile-table">
                      <span v-if="user.name">{{ user.name.substring(0, 1) }}</span>
                      <!-- This seems static; adjust as needed -->
                    </div>
                    {{ user.name }}
                  </div>
                </td>
                <!-- Assuming you want to display user_id here -->
                <td>
                  {{ user.available_credits }}
                </td>
                <!-- <td>{{ user.status }}</td> -->
                <!-- <td>{{ user.date }}</td> -->
                <td v-if="activeTab === 'dr'">
                  {{ user.recipient_name }} ({{ user.transfer_to }})
                </td>
                <td>
                  <button @click="clearCredits(user.member_id)">Clear</button>
                  <!-- Implement editing functionality as needed -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination Controls -->
          <PaginationPage
          :pagination="paginationData"
          @page-changed="handlePageChange"
        />
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import _ from 'lodash';

import DashboardLayout from "../dashboard/DashboardLayout.vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import ApiList from "@/ApiList";
import PaginationPage from "../PaginationPage.vue";
import axios from "axios";

export default {
  name: "CreditHistory",
  components: { DashboardLayout,PaginationPage },
  mixins: [ApiList],
  data() {
    return {
      creditsList: [],
      userDetails: [],
      isUserDetails: false,
      activeTab: "cr", // 'cr' for Unused Credits, 'dr' for Transferred Credits
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      user_id: "",
      member_id: "",
      searchQuery: "", // Make sure this is defined
      sortKey: "credits",
      sortOrder: "asc", // or 'desc'
      msg: "",
      paginationData: {},

    };
  },
  mounted() {
    const tokenVal = localStorage.getItem("token");
    if (tokenVal) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${tokenVal}`;
    }

    this.member_id = localStorage.getItem("user_id");
    this.apis.member_id = localStorage.getItem("member_id");
    this.apis.member_name = localStorage.getItem("member_name");
    // alert("ff");
    this.loadCreditsList();

  },

  // computed: {
  //   filteredUsers() {
  //     // Check if users data is available
  //     if (!this.users || this.users.length === 0) {
  //       return []; // Return an empty array if users data is not available
  //     }

  //     const searchLower = this.searchQuery.toLowerCase();

  //     let result = this.users.filter((user) => {
  //       // Check if the user matches the active tab
  //       if (user.status !== this.activeTab) {
  //         return false;
  //       }

  //       // Check if any of the specified fields match the search query
  //       return (
  //         user.name?.toLowerCase().includes(searchLower) ||
  //         user.member_id?.toString().toLowerCase().includes(searchLower) ||
  //         user.credits?.toString().toLowerCase().includes(searchLower) ||
  //         user.status?.toLowerCase().includes(searchLower) ||
  //         user.date?.toLowerCase().includes(searchLower) ||
  //         (this.activeTab === "dr" &&
  //           user.recipient_name?.toLowerCase().includes(searchLower)) // Search in recipient_name only for transferred credits
  //       );
  //     });

  //     // Sort the filtered result
  //     result.sort((a, b) => {
  //       let comparison = 0;
  //       if (a[this.sortKey] < b[this.sortKey]) comparison = -1;
  //       if (a[this.sortKey] > b[this.sortKey]) comparison = 1;
  //       return this.sortOrder === "asc" ? comparison : -comparison;
  //     });

  //     // Apply pagination
  //     const start = (this.currentPage - 1) * this.pageSize;
  //     const end = start + this.pageSize;
  //     if (result.length == 0) {
  //       // result=
  //     }
  //     return result.slice(start, end);
  //   },

  //   paginatedUsers() {
  //     const start = (this.currentPage - 1) * this.pageSize;
  //     const end = start + this.pageSize;
  //     return this.filteredUsers.slice(start, end);
  //   },
  // },

  methods: {
    // search
    debouncedSearch: _.debounce(function() {
      // Your search logic here (e.g., calling an API)
      this.searchValue();
      console.log('Searching for:', this.searchQuery);
      // Replace the console.log with your actual search implementation
    }, 1000), // Specify the debounce delay in milliseconds

    async searchValue() {
      const url = this.apis.apiUrl + "memberList";

      const response = await axios.get(url, { params: { search:this.searchQuery } });
      this.usersList = response.data.data;
    },
    
    //Apis
    getUserDetails(id) {
      this.$router.push(`/credits-history/${id}`);
    },
    async loadCreditsList() {
      const url = this.apis.apiUrl + "creditAvailable";
      axios
        .get(url, {
          params: {
            // status: "paid",
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          console.log(response.data.data);
          let pageData = response.data;
          if (response.data && response.data.data) {
          this.creditsList = response.data.data;
          this.last_page = pageData.last_page;
              // alert(this.last_page)
              this.paginationData.total = pageData.total;
              this.paginationData.current_page = pageData.current_page;
              this.paginationData.last_page = pageData.last_page;
              this.paginationData.links = pageData.links;
              this.paginationData.next_page_url = pageData.next_page_url;
              this.paginationData.path = pageData.path;
              this.paginationData.per_page = pageData.per_page;
              this.paginationData.prev_page_url = pageData.prev_page_url;
              this.paginationData.to = pageData.to;
              this.paginationData.total = pageData.total;
          // alert(this.creditList.length);
        } else {
              this.creditsList = [];
            }
        });
    },
    async clearCredits(userId) {
      const formData = new FormData();
      formData.append('user_id', userId);

      const credit_url = this.apis.apiUrl + "clearCredit";
      axios
        .post(credit_url, formData)
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }

          this.msg = response.data.msg;
          this.loadCreditsList();

        })

        .catch((error) => {
          //  alert(error)
          console.error("Error clearing credits:", error);
        });
    },

    // async fetchUserList() {
    //   try {
    //     var url = this.apis.apiUrl + this.apis.creditHistory;
    //     // var url = this.apis.apiUrl + this.apis.creditAvailable;
    //     var tokenVal = localStorage.getItem("token");

    //     // Axios GET request
    //     const response = await axios.get(url, {
    //       headers: {
    //         Authorization: `Bearer ${tokenVal}`,
    //       },
    //     });

    //     // With Axios, response data is accessed directly, no need to call .json()
    //     this.users = response.data; // Access the 'data' field directly from Axios response
    //     alert(this.users.length);
    //     this.filteredUsers = response.data;
    //   } catch (error) {
    //     console.error(
    //       "Error fetching user list:",
    //       error.response ? error.response.data : error
    //     );
    //     this.users = []; // Reset users to an empty array on error
    //   }
    // },

    sortUsers(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {

        this.sortOrder = "asc"; // or 'desc' if you prefer to start with descending order for new columns
      }
      this.sortKey = key;

      // this.forceRefresh();
    },

    // nextPage() {
    //   if (this.currentPage * this.pageSize < this.filteredUsers.length)
    //     this.currentPage++;
    // },
    // prevPage() {
    //   if (this.currentPage > 1) this.currentPage--;
    // },

    exportAsPdf() {
      html2canvas(document.getElementById("pdf-table")).then((canvas) => {
       
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("table.pdf");
      });
    },
    handlePageChange(pageUrl) {
      const url = pageUrl;
      axios
        .get(url, {
          params: {
            // status: "unpaid",
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
            alert(response.data.msg);
            this.logoutAll();
          }
          this.creditsList = response.data.data;
          let pageData = response.data;
          this.paginationData.current_page = pageData.current_page;
          this.paginationData.links = pageData.links;
          this.paginationData.next_page_url = pageData.next_page_url;
          this.paginationData.path = pageData.path;
          this.paginationData.per_page = pageData.per_page;
          this.paginationData.prev_page_url = pageData.prev_page_url;
          this.paginationData.to = pageData.to;
          this.paginationData.total = pageData.total;
          // console.log("aa"+JSON.stringify(this.usersList))
        });
    },
  },
};
</script>
