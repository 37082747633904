<template>
  <div class="position-sticky">
    <div class="text-center">
      <img
        src="../../assets/images/best-business-alliance.png"
        alt="BBA"
        class="logo"
      />
    </div>
    <ul class="side-links">
      <!-- <h1 v-if="isadmin" class="h4 text-primary">Admin Dashboard</h1> -->
      <!-- <h1 v-else class="h4 text-primary">Member Dashboard</h1> -->
      <li v-if="!isadmin">
        <router-link to="/member-details" class="dropdown-item"
          ><img src="../../assets/images/user.svg" alt="BBA" class="me-1" /> My
          Profile</router-link
        >
      </li>
      <li v-if="isadmin">
        <router-link to="/dashboard">
          <img src="../../assets/images/home.svg" alt="BBA" class="me-1" />
          Dashboard</router-link
        >
      </li>

      <li v-if="isadmin">
        <router-link to="/members-list">
          <img src="../../assets/images/list.svg" alt="BBA" class="me-1" />

          Members List</router-link
        >
      </li>
      <li v-if="!isadmin">
        <router-link to="/members-list">
          <img src="../../assets/images/list.svg" alt="BBA" class="me-1" />
          My Direct Sponser</router-link
        >
      </li>

      <li v-if="isadmin">
        <router-link to="/announcements">
          <img
            src="../../assets/images/announcement.svg"
            alt="BBA"
            class="me-1"
          />

          Announcements</router-link
        >
      </li>

      <li v-if="!isadmin">
        <router-link to="/transfer-credit">
          <img src="../../assets/images/credits.svg" alt="BBA" class="me-1" />
          Transfer Credits</router-link
        >
      </li>
      <li v-if="isadmin">
        <router-link to="/issue-credit">
          <img src="../../assets/images/credits.svg" alt="BBA" class="me-1" />

          Issue Credits</router-link
        >
      </li>
      <li v-if="isadmin">
        <router-link to="/available-credits">
          <img src="../../assets/images/credits.svg" alt="BBA" class="me-1" />

          Available Credits</router-link
        >
      </li>
      <li v-if="!isadmin">
        <router-link to="/credits-history">
          <img src="../../assets/images/credits.svg" alt="BBA" class="me-1" />

          My Credits History</router-link
        >
      </li>
      <li v-if="isadmin">
        <router-link to="/unpaid-payments">
          <img src="../../assets/images/payments.svg" alt="BBA" class="me-1" />

          Unpaid Payments</router-link
        >
      </li>
      <li v-if="isadmin">
        <router-link to="/paid-payments">
          <img src="../../assets/images/payments.svg" alt="BBA" class="me-1" />

          Paid Payments</router-link
        >
      </li>
      <!-- <li v-if="isadmin">
        <router-link to="/unpaid-payments">
          <img src="../../assets/images/payments.svg" alt="BBA" class="me-1" />

          Unpaid Sponser Payments</router-link
        >
      </li> -->
      <!-- <li v-if="isadmin">
        <router-link to="/paid-payments">
          <img src="../../assets/images/payments.svg" alt="BBA" class="me-1" />

         Paid Sponser Payments</router-link
        >
      </li> -->

      <li v-if="!isadmin">
        <router-link to="level-bonus">
          <img src="../../assets/images/payments.svg" alt="BBA" class="me-1" />

          Loyalty Bonus
        </router-link>
      </li>
      <li v-if="!isadmin">
        <router-link to="sponser-bonus">
          <img src="../../assets/images/payments.svg" alt="BBA" class="me-1" />

          Sponser Bonus
        </router-link>
      </li>
      <!-- <li>
        <router-link to="PopUp"> PopUp</router-link>
      </li>
      <li>
        <router-link to="EditTesting"> EditTesting</router-link>
      </li> -->
    </ul>
    <ul v-if="!isadmin" class="member-md">
      <li>
        Joining Date
        <span> {{ formattedJoiningDate(levelData.joining_date) }} </span>
      </li>
      <li v-if="levelData.active_level == 'NA'">
        Active Level & Date
        <span
          >{{ levelData.active_level }} ({{ levelData.active_level_date }})
        </span>
      </li>
      <li v-else>
        Active Level & Date
        <span
          >{{ levelData.active_level }} ({{
            formattedJoiningDate(levelData.active_level_date)
          }})
        </span>
      </li>
      <li v-if="levelData.last_acheived_level == 'NA'">
        Last Achived Level & Date<span
          >{{ levelData.last_acheived_level }} ({{
            levelData.last_acheived_level_date
          }})
        </span>
      </li>
      <li v-else>
        Last Achived Level & Date<span
          >{{ levelData.last_acheived_level }} ({{
            formattedJoiningDate(levelData.last_acheived_level_date)
          }})
        </span>
      </li>
      <li>
        Sales After Activation :
        <span>{{ levelData.member_after_activation }} </span>
      </li>
      <li>
        Available Credits :
        <span>{{ levelData.credits }} </span>
      </li>
      <li>
        Autopool :
        <span>{{ levelData.autopool }} </span>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import ApiList from "@/ApiList";
export default {
  name: "SidebarClient",
  mixins: [ApiList],
  data() {
    return {
      isLoading: true,
      role: "",
      isadmin: false,
      ismember: false,
      levelData: {},
    };
  },
  mounted() {
    this.onLoad();
    const tokenVal = localStorage.getItem("token");
    if (
      !Object.prototype.hasOwnProperty.call(
        axios.defaults.headers.common,
        "Authorization"
      )
    ) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${tokenVal}`;
    }
    this.loadMemberDates();
    // alert(this.apis.member_name)
  },
  methods: {
    formattedJoiningDate(dt) {
      // let dt = dtValue.toString();
      // alert(dt)
      // Assuming user.date_of_joining is in yyyy/mm/dd format
      if (!dt) return "";
      if (dt.length > 0) {
        const parts = dt.split("-");
        if (parts.length !== 3) return "Invalid date";

        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Format: dd/mm/yyyy
        return `${day}-${month}-${year}`;
      } else return "";
    },
    onLoad() {
      this.role = localStorage.getItem("role");
      if (this.role == "admin") {
        this.isadmin = true;
      } else {
        this.isadmin = false;
      }
    },
    loadMemberDates() {
      const url = this.apis.apiUrl + "sidebar_date_count";
      try {
        axios
          .get(url, {
            params: {},
          })
          .then((response) => {
            if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
            this.levelData = response.data;
            // alert(JSON.stringify(this.levelData))
          });
      } catch (error) {
        console.error("Error loading member list:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
