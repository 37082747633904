<template>
    <DashboardLayout>
      <div class="w-container mt-5">
        <div class="head-section border-bottom pb-3">
          <h3 class="h4">Announcement</h3>
          <router-link to="/announcements-list">Announcements List</router-link>
        </div>
        <div class="mt-4">
          <div class="table-sort ">
            <!-- Success Message -->
            <div v-if="successMsg" class="alert alert-success">{{ successMsg }}</div>
            <!-- Error Message -->
            <div v-if="errorMsg" class="alert alert-danger" v-html="errorMsg"></div>
  
            <div class="row">
              <div class="col-xl-7">
                <form class="form-style-1">
                  <div class="row">
              
                    <div class="width100">
                      <label for="mName" class="form-label">Title <span>*</span></label>
                      <input type="text" class="form-control" id="mName" v-model.trim="formData.title" required>
                    </div>
               
                
                    <div class="width100">
                      <label for="address" class="form-label">Message</label>
                      <textarea class="form-control" id="address" rows="4" v-model.trim="formData.details" required></textarea>
                    </div>
                    <div class="mt-3">
                      <input type="button" value="Send Message" class="p-btn" @click="saveData">
                      <input type="button" value="Cancel" class="s-btn ms-3" @click="clearFormData">
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-xl-5">
                <div class="video-container text-center">
                  <img src="../../src/assets/images/announcement.png" class="col-xl-8"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  </template>
  
  <script>
  import DashboardLayout from "./dashboard/DashboardLayout.vue";
  import ApiList from "@/ApiList";
  import axios from "axios";
  
  export default {
    name: "AnnouncementsMessage",
    components: { DashboardLayout },
    mixins: [ApiList],
    data() {
      return {
        formData: {
          sponsor_id: "",
          title: "",
          details: "",
          
        },
        successMsg: "",
        errorMsg: "",
      };
    },
    mounted() {
      this.apis.member_id = localStorage.getItem("member_id");
      this.apis.member_name = localStorage.getItem("member_name");
      this.formData.sponsor_id = this.apis.member_id;
    },
    methods: {
      limitLength(field, maxLength) {
      if (this.formData[field].length > maxLength) {
        this.formData[field] = this.formData[field].slice(0, maxLength);
      }
    },
      async saveData() {
        try {
          this.successMsg = "";
      this.errorMsg = "";
          const formData = new FormData();
          // formData.append("sponsor_id", this.formData.sponsor_id);
          formData.append("title", this.formData.title);
          formData.append("details", this.formData.details);
         
          var url = this.apis.apiUrl + this.apis.saveAnnouncement;
          var tokenVal = localStorage.getItem("token");
  
          const response = await axios.post(url, formData, {
            headers: {
              Authorization: `${tokenVal}`,
            },
          });
  
          this.successMsg = response.data.msg;
          this.clearFormData();
        } 
        catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      this.errorMsg = "<ul class='p-2'>" + Object.values(errors).flat().map(error => `<li>${error}</li>`).join("") + "</ul>";
    } else {
      this.errorMsg = "An error occurred while saving the Announcement details.";
    }
    console.error(error);
  }
  
      },
      clearFormData() {
        // Reset form data
        this.formData = {
          sponsor_id: this.apis.member_id,
          name: "",
          father_name: "",
          address: "",
          pan_card: "",
          aadhar_card: "",
          mobile_no: "",
        };
      },
    },
  };
  </script>
  