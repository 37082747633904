<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <h2 class="h4 border-bottom pb-3">
      Credits History Of
        <span class="badge rounded-pill bg-success">
          {{ userId}}
        </span>
        
        
      </h2>
      <div class="mt-4">
        <div class="display-flex mb-2">
          <div>
            <input
              type="search"
              class="search-form-control"
              placeholder="Search..."
              v-model="searchQuery"
            />
            <!-- <button class="export-btn ms-3" @click="exportAsPdf">
              Export as Excel <span class="material-icons"> print </span>
            </button> -->
          </div>
        </div>

        <div class="table-sort">
          <table class="table" id="pdf-table">
            <thead>
              <tr>
                <th scope="col" @click="sortUsers('user_id')">
                  Date <span class="material-icons">swap_vert</span>
                </th>
                <th scope="col" @click="sortUsers('name')">
                  Issued Pin(cr) <span class="material-icons">swap_vert</span>
                </th>

                <th scope="col" @click="sortUsers('credits')">
                  Pin Used(dr) <span class="material-icons">swap_vert</span>
                </th>
                <th scope="col">Transfer to</th>
                <!-- <th scope="col">Total</th> -->
              </tr>
            </thead>
            <!-- {{
              users
            }} -->
            <tbody>
              <tr v-for="user in userDetails" :key="user.member_id">
                <td>{{ formattedJoiningDate(user.date) }}</td>
                <!-- <td>{{ user.date }}</td> -->
                <td>
                  {{ user.status == "cr" ? user.credits : 0 }}
                </td>
                <td>
                  {{ user.status == "dr" ? user.credits : 0 }}
                </td>
                <td>
                  {{ user.transfer_to }}
                </td>
                </tr>
              <!-- <tr v-for="users in creditsList" :key="users.member_id">
                <td>
                  {{ users.available_credits }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- Pagination Controls -->
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click="prevPage">Previous</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
import ApiList from "@/ApiList";
import axios from "axios";

export default {
  name: "CreditHistory",
  components: { DashboardLayout },
  props: ["item"],
  mixins: [ApiList],
  data() {
    return {
      userRole:"",
      creditsList: {},
      userDetails: [],
      activeTab: "cr",
      userId: "",
      searchQuery: "", // Make sure this is defined
      sortKey: "credits",
      sortOrder: "asc", // or 'desc'
    };
  },
  created() {
    this.userId = this.$route.params.id;
    // alert("call load " + this.$route.params.id);
  },
  mounted() {
    this.apis.user_id = localStorage.getItem("user_id");
    this.apis.member_id = localStorage.getItem("member_id");
    this.apis.member_name = localStorage.getItem("member_name");
    this.userRole = localStorage.getItem("role");
    this.loadCreditHistory();
    this.loadCreditsList();
  },
  methods: {
    formattedJoiningDate(dt) {
      // let dt = dtValue.toString();
      // alert(dt)
    // Assuming user.date_of_joining is in yyyy/mm/dd format
    if (!dt) return '';
      if(dt.length>0)
    {const parts = dt.split('-');
    if (parts.length !== 3) return 'Invalid date';

    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    // Format: dd/mm/yyyy
    return `${day}-${month}-${year}`;
    }
    else return '';
  },
    // async loadCreditHistory(id) {

    async loadCreditHistory() {
      this.memberId = localStorage.getItem("member_id");
      
      const credit_url = this.apis.apiUrl + "creditHistory";
      axios
        .get(credit_url, {
        
          params: { member_id: this.userId },

        })
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          this.userDetails = response.data.data;
          // alert(this.creditList.length);
        });
    },

    // load credit list
    async loadCreditsList() {
      // this.memberId = localStorage.getItem("member_id");
      const url = this.apis.apiUrl + "creditAvailable";
      axios
        .get(url, {
          params: {
            member_id: this.userId,
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          if (this.userRole=="member"){
            this.creditsList = response.data[0];
          }
          else{
            this.creditsList = response.data.data[0];

          }
          // alert(JSON.stringify(response.data[0]));
        });
    },

    // exportAsPdf() {
    //   html2canvas(document.getElementById("pdf-table")).then((canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     const pdf = new jsPDF();

    //     const imgProps = pdf.getImageProperties(imgData);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    //     pdf.save("table.pdf");
    //   });
    // },
  },
};
</script>
