<template>
    <DashboardLayout>
     <div class="w-container mt-5">
        <div class="row">
            <div class="width100">
                
                <div class="profile-card">
                    <h1>Welcome, Mohit Kumar 👋 <span class="badge h6 rounded-pill bg-primary fs-6 ">Level 1</span></h1> 
                    <!-- <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis veniam quaerat eos, neque asperiores molestiae dolor officia impedit eaque sequi.</p> -->
                    <div class="dash-summery">
                        <div class="card-items-bba-1 light-green">
                            <div class="card-details">
                                <span>Date of Joining</span>
                                <h4>25 April, 2023</h4>
                            </div>
                        </div>
                        <div class="card-items-bba-1 peel-light">
                            <div class="card-details">
                                <span>Direct Sponsor (DP)</span>
                                <h4>80</h4>
                            </div>
                        </div>
                        <div class="card-items-bba-1 trust-light">
                            <div class="card-details">
                                <span>Wallet Balance</span>
                                <h4>₹ 10,000</h4>
                            </div>
                        </div>
                        <div class="card-items-bba-1 red-light">
                            <div class="card-details">
                                <span>Total Earning</span>
                                <h4>₹ 20,000</h4>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div>
            <div class="mt-5 col-xl-5 profile-card level-section">
                <h2 class="h5 mb-3">Level</h2> 
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Levels</th>
                        <th scope="col">Active Date</th>
                        <th scope="col">Completed Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Level 1</td>
                        <td>1 April 2023</td>
                        <td>15 August 2024</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Level 2</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Level 3</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>

                <!-- <div class="list-group">
                    <div type="button" class="list-group-item list-group-item-action list-group-item-success">
                        <h3 class="h6">Level 1</h3>
                        <p><span>Completed Date : </span>20 April 2023</p>
                    </div>
                    <button type="button" class="list-group-item list-group-item-action list-group-item-success">Level 2</button>
                    <button type="button" class="list-group-item list-group-item-action list-group-item-primary" aria-current="true">
                      Level 3
                    </button>
                    <button type="button" class="list-group-item list-group-item-action disabled">Level 4</button>
                    <button type="button" class="list-group-item list-group-item-action disabled">Level 5</button>
                    <button type="button" class="list-group-item list-group-item-action disabled">Level 6</button>
                    <button type="button" class="list-group-item list-group-item-action" disabled>Level 8</button>
                  </div> -->

            </div>
            <!-- <div class="col-xl-5">
                <div class="col-xl-11 mx-auto">
                    <div class="dash-summery">
                        <div class="card-items-bba mb-3 light-green">
                            <div class="card-details">
                                <span>25</span>
                                <h4>Date of Joining</h4>
                            </div>
                        </div>
                        <div class="card-items-bba mb-3 peel-light">
                            <div class="card-details">
                                <span>8000</span>
                                <h4>Direct Sponsor (DP)</h4>
                            </div>
                        </div>
                        <div class="card-items-bba trust-light">
                            <div class="card-details">
                                <span>₹ 10,000</span>
                                <h4>Wallet Balance</h4>
                            </div>
                        </div>
                        <div class="card-items-bba red-light">
                            <div class="card-details">
                                <span>₹ 20,000</span>
                                <h4>Total Earning</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</DashboardLayout>             
</template>

<script>
import DashboardLayout from "./../dashboard/DashboardLayout.vue";
// import { ref, computed } from 'vue';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
export default {
    name: 'MemberDashboard',
    components:{DashboardLayout},
}
</script>