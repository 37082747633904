<template>
  <div>
   <router-view></router-view>
  </div>

</template>

<script>
// import DashboardClient from './components/dashboard/DashboardClient.vue';
export default {
  name: 'App',
  // components: {
  //   DashboardClient
  // }
}
</script>
