<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <div class="head-section border-bottom pb-3">
        <h3 class="h4">Transfer Credits</h3>
        <router-link to="/credits-history">Credit History</router-link>
      </div>
      <div class="mt-4">
        <div class="table-sort border-l">
          <div v-if="successMsg" class="alert alert-success">
            {{ successMsg }}
          </div>
          <div
            v-if="errorMsg"
            class="alert alert-danger"
            v-html="errorMsg"
          ></div>
          <div class="row">
            <div class="col-xl-7">
              <form @submit.prevent="saveData" class="form-style-1">
                <div class="row">
                  <div class="col-xl-6">
                    <label for="memberSearch" class="form-label"
                      >Member ID <span>*</span></label
                    >
                    <input
                      class="form-control"
                      id="memberSearch"
                      v-model="apis.member_id"
                      readonly
                    />

                    <!-- {{ apis.member_id }} -->
                  </div>
                  <div class="col-xl-6">
                    <label for="mName" class="form-label"
                      >Transfer To<span>*</span></label
                    >
                    <input
                      list="member-ids"
                      class="form-control"
                      id="memberSearch"
                      v-model="selectedMember"
                      @input="handleMemberSelection"
                    />
                    <datalist id="member-ids">
                      <option
                        v-for="member in members"
                        :value="`${member.member_id}`"
                        :key="member.id"
                      >
                        {{ member.name }} - {{ member.member_id }}
                      </option>
                    </datalist>
                  </div>
                  <div class="col-xl-6">
                    <label for="mName" class="form-label"
                      >Credits<span>*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="mName"
                      v-model.trim="formData.credits"
                      required
                    />
                  </div>
                  <div class="width100 mt-2">
                    <div class="row">
                      <div class="col-xl-6">
                        <label for="captchaDisplay" class="form-label"
                          >Solve captcha <span>*</span></label
                        >
                        <div class="captcha" id="captchaDisplay">
                          {{ captchaValue }}
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <label for="captchaInput" class="form-label"
                          >Enter Captcha code <span>*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="captchaInput"
                          v-model.trim="userCaptchaInput"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <input
                    v-if="!isSaving"

                      type="submit"
                      value="Transfer Credit"
                      class="p-btn"

                    />
                    <input
                      type="button"
                      value="Cancel"
                      class="s-btn ms-3"
                      @click="clearFormData"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-xl-5">
              <div class="client-details" v-if="selectedMember">
                <h2 class="h5 border-bottom pb-3 mb-4">
                  Member details to be issue pin
                </h2>
                <ul class="member-details">
                  <li>
                    Name <span>{{ memberDetails.name }}</span>
                  </li>
                  <li>
                    Sponsor ID <span>{{ memberDetails.sponser_id }}</span>
                  </li>
                  <li>
                    Father/Husband Name
                    <span>{{ memberDetails.father_name }}</span>
                  </li>
                  <li>
                    Date of Joining
                    <span>{{ memberDetails.date_of_joining }}</span>
                  </li>
                  <li>
                    Mobile No. <span>{{ memberDetails.mobile_no }}</span>
                  </li>
                  <li>
                    Address <span>{{ memberDetails.address }}</span>
                  </li>
                </ul>
              </div>
              <div v-else>
                <img src="../../assets/images/credit-issue.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import ApiList from "@/ApiList";
import axios from "axios";

const tokenVal = localStorage.getItem("token");
if (tokenVal) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokenVal}`;
}

export default {
  name: "CreditIssue",
  components: {
    DashboardLayout,
  },
  mixins: [ApiList],
  data() {
    return {
      formData: {
        credits: "",
      },
      isSaving: false,

      memberDetails: {},
      transferList: [],
      selectedMemberDisplay: "", // Input display for selected member
      user_id: "", // Selected member's s_no
      successMsg: "",
      errorMsg: "",
      selectedMember: null, // Holds the selected member object
      members: [], // List of members fetched from the API
      captchaValue: "", // Generated captcha value
      userCaptchaInput: "", // User input for captcha verification
    };
  },
  mounted() {
    this.onLoad();
    this.apis.member_name = localStorage.getItem("member_name");
    this.apis.member_id = localStorage.getItem("member_id");
    // alert(this.user_id)
    this.fetchMembers();
    this.generateCaptcha();
    // this.creditsTransfer();
  },
  methods: {
    // show members

    handleMemberSelection() {
      // const member = this.members.find(m => `${m.name} - ${m.member_id}` === this.selectedMemberDisplay);
      // this.user_id = member ? member.member_id : "";
      // this.selectedMember = member || null;
      this.user_id = this.selectedMember;
      this.getMemberDetail();
    },
    async getMemberDetail() {
      let mId = this.selectedMember;
      // alert("id = " + mId)
      const url = this.apis.apiUrl + "member-details";

      if (mId.includes("BB-")) {
            // alert("found");
          } else {
            mId = `BB-${mId}`;
            // alert("found");
            // Check if userId starts with 'BB-', if not prepend it
          }
      const response = await axios.get(url, {
        params: { memberid: mId },
      });
      this.memberDetails = response.data;
      if (Object.keys(this.memberDetails).length > 0) {
        this.isMemberValid = true;
      } else {
        this.isMemberValid = false;
      }
    },

    onLoad() {
      this.user_id = localStorage.getItem("member_id");
    },
    // creditsTransfer(){
    //   const url = this.apis.apiUrl + "transferCredit";
    //     axios
    //       .post(url, {
    //         params: {
    //           // status: "paid",
    //         },
    //       })
    //       .then((response) => {
    //         this.transferList = response.data.data;
    //       });
    // },
    generateCaptcha() {
      // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const characters = "0123456789";
      let result = "";
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.captchaValue = result;
    },

    // handleMemberSelection() {
    //   // const member = this.members.find(m => `${m.name} - ${m.member_id}` === this.selectedMemberDisplay);
    //   // this.user_id = member ? member.member_id : "";
    //   // this.selectedMember = member || null;
    //   this.user_id = this.selectedMember;
    // },

    async fetchMembers() {
      try {
        const tokenVal = localStorage.getItem("token");
        var url = this.apis.apiUrl + this.apis.memberList;
        const response = await axios.get(url, {
          headers: { Authorization: `${tokenVal}` },
        });
        if (response.data && Array.isArray(response.data.data)) {
          this.members = response.data.data;
        } else {
          console.error("Invalid response data:", response.data);
          this.errorMsg = "Invalid response data received from the server.";
        }
      } catch (error) {
        console.error("Failed to fetch members:", error);
        this.errorMsg = "Failed to load members list.";
      }
    },

    async saveData() {
      if (this.userCaptchaInput !== this.captchaValue) {
        alert("Captcha does not match. Please try again.");
        this.generateCaptcha();
        return;
      }
      if (this.isMemberValid == false) {
        alert("Plz Select Valid Member.");
        // this.generateCaptcha();
        return;
      } else {
        this.isSaving = true;
        try {
          this.successMsg = "";
          this.errorMsg = "";
          const formData = new FormData();
          formData.append("user_id", this.user_id);
          formData.append("pins", this.formData.credits);
          const url = this.apis.apiUrl + "transferCredit";
          const tokenVal = localStorage.getItem("token");

          const response = await axios.post(url, formData, {
            headers: { Authorization: `${tokenVal}` },
          });

          this.successMsg = response.data.msg;
          this.clearFormData();
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    clearFormData() {
      this.formData = { credits: "" };
      this.selectedMemberDisplay = "";
      this.user_id = "";
      this.selectedMember = null;
      this.userCaptchaInput = "";
      this.generateCaptcha(); // Regenerate captcha on form reset
    },

    handleError(error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        this.errorMsg = `<ul class='p-2'>${Object.values(errors)
          .flat()
          .map((err) => `<li>${err}</li>`)
          .join("")}</ul>`;
      } else {
        this.errorMsg = "An error occurred while saving the member details.";
      }
      console.error(error);
    },
  },
};
</script>
