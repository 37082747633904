<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <div class="head-section border-bottom pb-3">
        <h3 class="h4">Register New Member</h3>
        <router-link to="/members-list">Members List</router-link>
      </div>
      <div class="mt-4">
        <div class="table-sort border-l">
          <!-- Success Message -->
          <div v-if="successMsg" class="alert alert-success">{{ successMsg }}</div>
          <!-- Error Message -->
          <div v-if="errorMsg" class="alert alert-danger" v-html="errorMsg"></div>

          <div class="row">
            <div class="col-xl-7">
              <form class="form-style-1">
                <div class="row">
                  <div class="form-input-wrap">
                    <label for="sponsor_id" class="form-label">Sponsor ID <span>*</span></label>
                    <input type="text" class="form-control" id="sponsor_id" v-model.trim="formData.sponsor_id" readonly>
                  </div>
                  <div class="form-input-wrap">
                    <label for="mName" class="form-label">Name <span>*</span></label>
                    <input type="text" class="form-control" id="mName" v-model.trim="formData.name" required>
                  </div>
                  <div class="form-input-wrap">
                    <label for="mName" class="form-label">Father/Husband Name <span>*</span></label>
                    <input type="text" class="form-control" id="mName" v-model.trim="formData.father_name" required>
                  </div>
                  <div class="form-input-wrap">
                    <label for="mobileNo" class="form-label">Mobile No. <span>this*</span></label>
                    <input type="text" class="form-control" id="mobileNo" v-model.trim="formData.mobile_no"
                      @input="limitLength('mobile_no', 10)" required>
                  </div>
                  <div class="form-input-wrap">
                    <label for="panCard" class="form-label">PAN Card</label>
                    <input type="text" class="form-control" id="panCard" @input="limitLength('pan_card', 10)"
                      v-model.trim="formData.pan_card">
                  </div>
                  <div class="form-input-wrap">
                    <label for="aadharCard" class="form-label">Aadhar Card</label>
                    <input type="text" class="form-control" id="aadharCard" @input="limitLength('aadhar_card', 12)"
                      v-model.trim="formData.aadhar_card">
                  </div>
                  <div class="width100">
                    <label for="address" class="form-label">Address</label>
                    <textarea class="form-control" id="address" rows="2" v-model.trim="formData.address"
                      required></textarea>
                  </div>
                  <div class="mt-3">
                    <div v-if="this.available_credits > 0">
                      <input v-if="!isSaving" type="button" value="Save Member Details" class="p-btn" @click="saveData">
                      <input v-else type="button" value="Save Member Details" class="p-btn">
                      <input type="button" value="Cancel" class="s-btn ms-3" @click="clearFormData">
                    </div>
                    <div v-else>
                      No Credits Available. Contact Office.
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-xl-5">
              <div class="video-container">
                <iframe class="responsive-iframe" style="width:100%; height:250px; border:0;"
                  src="https://www.youtube.com/embed/4-YV1cFartQ" frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import ApiList from "@/ApiList";
import axios from "axios";

export default {
  name: "AddNewMember",
  components: { DashboardLayout },
  mixins: [ApiList],
  data() {
    return {
      isSaving: false,
      formData: {
        sponsor_id: "",
        name: "",
        father_name: "",
        address: "",
        pan_card: "",
        aadhar_card: "",
        mobile_no: "",

      },
      successMsg: "",
      errorMsg: "",
      available_credits: 0,
    };
  },
  mounted() {
    this.apis.member_id = localStorage.getItem("member_id");
    this.apis.member_name = localStorage.getItem("member_name");
    this.formData.sponsor_id = this.apis.member_id;
    this.loadCredits();
  },
  methods: {
    limitLength(field, maxLength) {
      if (this.formData[field].length > maxLength) {
        this.formData[field] = this.formData[field].slice(0, maxLength);
      }
    },
    loadCredits() {
      const url = this.apis.apiUrl + "creditAvailable";
      axios
        .get(url, {
          params: {
            user_id: localStorage.getItem("member_id"),
            form_active: 1
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          console.log("aa" + response.data[0].available_credits);
          this.available_credits = response.data[0].available_credits;
        });
    },
    async saveData() {
      try {
        this.isSaving = true;
        this.successMsg = "";
        this.errorMsg = "";
        const formData = new FormData();
        formData.append("sponsor_id", this.formData.sponsor_id);
        formData.append("name", this.formData.name);
        formData.append("father_name", this.formData.father_name);
        formData.append("address", this.formData.address);
        formData.append("aadhar_card", this.formData.aadhar_card);
        formData.append("pan_card", this.formData.pan_card);
        formData.append("mobile_no", this.formData.mobile_no);
        var url = this.apis.apiUrl + this.apis.saveMember;
        var tokenVal = localStorage.getItem("token");

        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `${tokenVal}`,
          },
        });
        this.successMsg = response.data.msg;
        this.isSaving = false;
        this.clearFormData();
      }
      catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          this.errorMsg = "<ul class='p-2'>" + Object.values(errors).flat().map(error => `<li>${error}</li>`).join("") + "</ul>";
        } else {
          this.errorMsg = "An error occurred while saving the member details.";
        }
        console.error(error);
      }

    },
    clearFormData() {
      // Reset form data
      this.formData = {
        sponsor_id: this.apis.member_id,
        name: "",
        father_name: "",
        address: "",
        pan_card: "",
        aadhar_card: "",
        mobile_no: "",
      };
    },
  },
};
</script>
