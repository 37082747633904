<template>
  <div>
    <div :class="['sidebar', { 'is-open': isOpen }]">
      <div class="">
        <h5 id="">{{ id }} Edit Basic Details</h5>
        <button
          type="button"
          class="btn-close text-reset toggle-button"
          @click="toggleSidebar"
        >
          <!-- {{ isOpen ? "Close" : "Open" }} -->
        </button>
      </div>
      <div v-if="msg" class="alert alert-success">
        {{ msg }}
      </div>
      <div class="">
        <div class="width100">
          <!-- Member Basis Details -->
          <div class="form-style-1">
            <div class="row">
              <div class="form-input-wrap-2">
                <label for="spID" class="form-label"
                  >Sponsor ID<span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="spID"
                  v-model="formdata.member_id"
                  :disabled="!ISadmin"
                />
              </div>
              <div class="form-input-wrap-2">
                <label for="mName" class="form-label"
                  >Name <span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="mName"
                  v-model="formdata.name"
                  :disabled="!ISadmin"
                />
              </div>
              <div class="form-input-wrap-2">
                <label for="fatherName" class="form-label"
                  >Father/Husband Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="fatherName"
                  v-model="formdata.father_name"
                />
              </div>
              <div class="form-input-wrap-2">
                <label for="dob" class="form-label">Date of birth</label>
                <input
                  type="date"
                  class="form-control"
                  id="dob"
                  v-model="formdata.dob"
                />
              </div>
              <div class="form-input-wrap-2">
                <label for="mobileNo" class="form-label"
                  >Mobile No. <span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="mobileNo"
                  v-model="formdata.mobile_no"
                  :disabled="!ISadmin"
                />
              </div>

              <div class="form-input-wrap-2">
                <label for="panCard" class="form-label">PAN Card</label>
                <input
                  type="text"
                  class="form-control"
                  id="panCard"
                  v-model="formdata.pan_card"
                />
              </div>
              <div class="width100">
                <label for="aadharCard" class="form-label">Aadhar Card</label>
                <input
                  type="text"
                  class="form-control"
                  id="aadharCard"
                  v-model="formdata.aadhar_card"
                />
              </div>
              <div class="width100">
                <label for="address" class="form-label">Address</label>
                <textarea
                  class="form-control"
                  id="address"
                  rows="2"
                  v-model="formdata.address"
                ></textarea>
              </div>
              <div class="mt-3">
                <input
                  type="button"
                  value="Update Details"
                  class="p-btn"
                  @click="updateMember()"
                />
                <!-- <input
                  type="button"
                  value="Update Details"
                  class="p-btn"
                /> -->
                <input type="reset" value="Cancel" class="s-btn ms-3" />
              </div>
            </div>
          </div>
          <!-- Member Basis Details End -->

          <!-- Member Bank Details -->
          <form class="form-style-1">
            <div class="row">
              <div class="width100">
                <label for="bankName" class="form-label"
                  >Bank Name <span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="bankName"
                  v-model="bankdata.bank_name"
                />
                <!-- {{ memberDetails?.name }} -->
              </div>
              <div class="form-input-wrap-2">
                <label for="mName" class="form-label"
                  >Bank Holder Name <span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="mName"
                  v-model="bankdata.account_holder_name"
                />
              </div>
              <div class="form-input-wrap-2">
                <label for="ifsc" class="form-label"
                  >IFSC Code <span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="ifsc"
                  v-model="bankdata.ifsc"
                />
              </div>
              <div class="width100">
                <label for="acc_no" class="form-label"
                  >Account No. <span>*</span></label
                >
                <input
                  type="number"
                  class="form-control"
                  id="acc_no"
                  v-model="bankdata.account_no"
                />
              </div>

              <div class="mt-3">
                <input
                  type="button"
                  value="Update Details"
                  @click="updateBank()"
                  class="p-btn"
                />
                <!-- <input
                  type="button"
                  value="Update Details"
                  class="p-btn"
                /> -->
                <input type="reset" value="Cancel" class="s-btn ms-3" />
              </div>
            </div>
          </form>
          <!-- Member Banck Details Section End -->

          <!-- Nominee Details -->
          <form class="form-style-1">
            <div class="row">
              <div class="width100">
                <label for="nominee_name" class="form-label"
                  >Nominee Name <span>*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="nomineedata.name"
                  id="nominee_name"
                />
              </div>
              <div class="width100">
                <label for="mName" class="form-label"
                  >Relation <span>*</span></label
                >
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="nomineedata.relation"
                >
                  <option selected>--Choose---</option>
                  <option value="husband">Husband</option>
                  <option value="wife">Wife</option>
                  <option value="son">Son</option>
                </select>
              </div>

              <div class="mt-3">
                <input
                  type="button"
                  value="Update Nominee"
                  @click="updateNominee()"
                  class="p-btn"
                />
                <!-- <input
                  type="button"
                  value="Update Nominee"
                  class="p-btn"
                /> -->
                <input type="reset" value="Cancel" class="s-btn ms-3" />
              </div>
            </div>
          </form>
          <!-- Nominee Details Section End -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiList from "@/ApiList";
import axios from "axios";

export default {
  name: "EditMember",
  //   components: { DashboardLayout },
  mixins: [ApiList],
  props: ["id", "basicDetails", "bankDetails", "nomineeDetails", "ISadmin"],
  data() {
    return {
      isOpen: false,
      memberId: "",
      userDetails: {},
      memberDetails: {},
      BankDetails: {},
      msg: "",
      NomineeDetails: {},
      formdata: {
        name: "",
        father_name: "",
        dob: "",
        mobile_no: "",
        pan_card: "",
        aadhar_card: "",
        address: "",
      },
      bankdata: {
        bank_name: "",
        account_holder_name: "",
        ifsc: "",
        account_no: "",
      },
      nomineedata: {
        name: "",
        relation: "",
      },
    };
  },
  mounted() {
    // alert("loadEdit")
    this.loadMemberData();
    this.loadBankData();
    this.loadNomineeData();

    // this.getMemberDetails();
  },
  methods: {
    toggleSidebar() {
      this.$emit("on-close", false); //child to parent command
    },
    loadMemberData() {
      this.isOpen = !this.isOpen;
      // alert((this.basicDetails).length)
      this.memberId = this.id;
      // alert(this.memberId);
      if (Object.keys(this.basicDetails).length == 0) {
        // this.memberId = localStorage.getItem("member_id");
        const url = this.apis.apiUrl + "member-details";
        axios
          .get(url, {
            params: { memberid: this.memberId },
          })
          .then((response) => {
            if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
            console.log(response);
            let memberData = response.data;
            this.formdata.member_id = memberData.member_id;
            this.formdata.name = memberData.name;
            this.formdata.father_name = memberData.father_name;

            this.formdata.dob = memberData.dob;
            this.formdata.mobile_no = memberData.mobile_no;
            this.formdata.pan_card = memberData.pan_card;
            this.formdata.aadhar_card = memberData.aadhar_card;
            this.formdata.address = memberData.address;
          });
      } else {
        // alert((this.basicDetails).length)
        let memberData = this.basicDetails;
        this.formdata.member_id = memberData.member_id;
        this.formdata.name = memberData.name;
        this.formdata.father_name = memberData.father_name;
        this.formdata.dob = memberData.dob;
        this.formdata.mobile_no = memberData.mobile_no;
        this.formdata.pan_card = memberData.pan_card;
        this.formdata.aadhar_card = memberData.aadhar_card;
        this.formdata.address = memberData.address;
      }
    },
    loadBankData() {
      console.log(this.bankDetails);
      if (Object.keys(this.bankDetails).length == 0) {
        console.log("call bank api");
        this.memberId = this.id; //localStorage.getItem("member_id");
        const url = this.apis.apiUrl + "member-bank-details";
        axios
          .get(url, {
            params: { memberid: this.memberId },
          })
          .then((response) => {
            if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
            console.log(response);
            let bankDataObj = response.data;
            this.bankdata.member_id = bankDataObj.member_id;
            this.bankdata.bank_name = bankDataObj.bank_name;
            this.bankdata.account_holder_name = bankDataObj.bank_holder_name;
            this.bankdata.ifsc = bankDataObj.ifsc_code;
            this.bankdata.account_no = bankDataObj.account_no;
          });
      } else {
        // console.log(response);
        let bankDataObj = this.bankDetails;
        this.bankdata.member_id = bankDataObj.member_id;
        this.bankdata.bank_name = bankDataObj.bank_name;
        this.bankdata.account_holder_name = bankDataObj.bank_holder_name;
        this.bankdata.ifsc = bankDataObj.ifsc_code;
        this.bankdata.account_no = bankDataObj.account_no;
      }
    },
    loadNomineeData() {
      if (Object.keys(this.nomineeDetails).length == 0) {
        this.memberId = this.id; //localStorage.getItem("member_id");
        const url = this.apis.apiUrl + "member-nominee-details";
        axios
          .get(url, {
            params: { memberid: this.memberId },
          })
          .then((response) => {
            if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
            console.log(response);
            let nomineeData = response.data;
            this.nomineedata.member_id = nomineeData.member_id;
            this.nomineedata.name = nomineeData.name;
            this.nomineedata.relation = nomineeData.relation;
          });
      } else {
        let nomineeData = this.nomineeDetails;
        this.nomineedata.member_id = nomineeData.member_id;
        this.nomineedata.name = nomineeData.name;
        this.nomineedata.relation = nomineeData.relation;
      }
    },
    updateMember() {
      this.role = localStorage.getItem("role");

      const url = this.apis.apiUrl + "updateBasicDetails";

      const formdata = new FormData();
      formdata.append("member_id", this.memberId);
      // alert(this.role);
      if (this.role === "admin") {
        formdata.append("name", this.formdata.name);
        formdata.append("mobile_no", this.formdata.mobile_no);
      }

      formdata.append("father_name", this.formdata.father_name);
      formdata.append("dob", this.formdata.dob);
      formdata.append("pan_card", this.formdata.pan_card);
      formdata.append("aadhar_card", this.formdata.aadhar_card);
      formdata.append("address", this.formdata.address);

      axios.post(url, formdata).then((response) => {
        if (response.data.error == "2") {
          alert(response.data.msg);
          this.logoutAll();
        }
        console.log(response);
        // alert(response)
        this.msg = response.data.msg;
        this.loadMemberData();

        // this.userDetails = response.data.data;
      });
    },
    updateBank() {
      const url = this.apis.apiUrl + "updateBankDetails";
      const bankdata = new FormData();
      bankdata.append("member_id", this.memberId);
      bankdata.append("bank_name", this.bankdata.bank_name);
      bankdata.append("account_holder_name", this.bankdata.account_holder_name);
      bankdata.append("ifsc", this.bankdata.ifsc);
      bankdata.append("account_no", this.bankdata.account_no);
      axios.post(url, bankdata).then((response) => {
        if (response.data.error == "2") {
          alert(response.data.msg);
          this.logoutAll();
        }
        console.log(response);
        // alert(response)
        this.msg = response.data.msg;
        this.loadBankData();
      });
    },
    updateNominee() {
      const url = this.apis.apiUrl + "updateNomineeDetails";
      const nomineedata = new FormData();
      nomineedata.append("member_id", this.memberId);
      nomineedata.append("name", this.nomineedata.name);
      nomineedata.append("relation", this.nomineedata.relation);

      axios.post(url, nomineedata).then((response) => {
        if (response.data.error == "2") {
          alert(response.data.msg);
          this.logoutAll();
        }
        console.log(response);
        // alert(response)
        this.msg = response.data.msg;
        this.loadNomineeData();
      });
    },
  },
};
</script>
<style scoped>
.sidebar {
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  color: #000000;
  position: fixed;
  overflow: auto;
  top: 0;
  right: -250px; /* Change left to right */
  transition: right 0.3s ease; /* Change left to right */
  z-index: 1000;
  overflow-y: auto;
  max-height: 100vh;
}

.sidebar.is-open {
  right: 0;
  padding: 10px;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: 1px;
  width: 50px;
  height: 30px;
  background-color: #fffcfc;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.sidebar-content {
  padding: 20px;
}
</style>
