<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <div class="mt-4">
        <div class="display-flex mb-2 border-bottom pb-3">
          <div class="flex-1">
            <h2 class="h4">Sponser Bonus </h2>
          </div>
          <div>
            <div class="row">
              <div class="col-xl-6">
                From<VueDatePicker v-model="date"></VueDatePicker>
              </div>
              <div class="col-xl-6">
                To<VueDatePicker v-model="date"></VueDatePicker>
              </div>
            </div>

            <!-- <button class="export-btn ms-3" @click="exportAsPdf">Export as Excel <span class="material-icons">
                        print
                        </span></button> -->
          </div>
        </div>

        <div class="table-sort">
          <table class="table" id="pdf-table">
            <thead>
              <tr>
               
               

             

                <!-- <th scope="col" >
                  Level <span class="material-icons"> swap_vert </span>
                </th> -->
                <th scope="col" >
                   Date <span class="material-icons"> swap_vert </span>
                </th>
                <th scope="col" >
                   Amount <span class="material-icons"> swap_vert </span>
                </th>
                
                <th scope="col" >
                  TDS  <span class="material-icons"> swap_vert </span>
                </th>
                <th scope="col" >
                  Admin Charges  <span class="material-icons"> swap_vert </span>
                </th>
                <th scope="col" >
                  Status  <span class="material-icons"> swap_vert </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in paidList" :key="index">
                <!-- <td>{{ user.user_id }}</td> -->
               
                <!-- <td>{{ user.income_type }}</td> -->
                <td>{{ user.payment_date }}</td>
                <td>₹ {{ user.amount }}</td>
                <td> {{ user.tds }}</td>
                <td> {{ user.admin_charges }}</td>
                <td> {{ user.status }}</td>

                <!-- <td>
                  <span class="badge rounded-pill bg-success">Success</span>
                  <span class="badge rounded-pill bg-danger">Failed</span>
                </td> -->
              </tr>
            </tbody>
          </table>
          <PaginationPage
            :pagination="paginationData"
            @page-changed="handlePageChange"
          />
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>
<script>
import axios from "axios";
import ApiList from "@/ApiList";
import PaginationPage from "../PaginationPage.vue";import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import DashboardLayout from "../dashboard/DashboardLayout.vue";

//Authorization Token
const tokenVal = localStorage.getItem("token");
if (tokenVal) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokenVal}`;
}

export default {
  name: "SponserBonus",
  mixins: [ApiList],
  components: { DashboardLayout,PaginationPage,VueDatePicker },
  data() {
    return {
      pageNo: 1,
      paidList: [],
      status: "",
      user: "",
      paginationData: {},
      user_id: "",
    };
  },
  mounted() {
    this.loadPayments();
    this.apis.member_id = localStorage.getItem("member_id");
    // alert(this.apis.member_id);
    this.apis.member_name = localStorage.getItem("member_name");
  },
  computed: {
    paginatedusersList() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredusersList.slice(start, end);
    },
    // Compute the total number of pages based on the filtered usersList and page size
    totalPages() {
      return Math.ceil(this.filteredusersList.length / this.pageSize);
    },
  },
  methods: {
    handlePageChange(pageUrl) {
      // Update the current page number or perform other actions
      // this.pageNo = pageNumber;
      const url = pageUrl;
      axios
        .get(url, {
          params: {
            // status: "unpaid",
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          this.paidList = response.data.data;
          let pageData = response.data;
          this.paginationData.current_page = pageData.current_page;
          this.paginationData.links = pageData.links;
          this.paginationData.next_page_url = pageData.next_page_url;
          this.paginationData.path = pageData.path;
          this.paginationData.per_page = pageData.per_page;
          this.paginationData.prev_page_url = pageData.prev_page_url;
          this.paginationData.to = pageData.to;
          this.paginationData.total = pageData.total;
          // console.log("aa"+JSON.stringify(this.usersList))
        });
    },
    loadPayments() {
      const url = this.apis.apiUrl + "payments";
      axios
        .get(url, {
          params: {
             income_type: "sponser",
            member_id:this.member_id,
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          this.paidList = response.data.data;
          let pageData = response.data;
          this.paginationData.current_page = pageData.current_page;
          this.paginationData.links = pageData.links;
          this.paginationData.next_page_url = pageData.next_page_url;
          this.paginationData.path = pageData.path;
          this.paginationData.per_page = pageData.per_page;
          this.paginationData.prev_page_url = pageData.prev_page_url;
          this.paginationData.to = pageData.to;
          this.paginationData.total = pageData.total;
          
        });
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    // Navigate to the previous page
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>
<!-- <script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import { ref, computed } from "vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "MemberPayments",
  components: { DashboardLayout, VueDatePicker },
  setup() {
    const currentPage = ref(1);
    const itemsPerPage = ref(2);
    const searchQuery = ref("");
    const sortKey = ref("");
    const sortAsc = ref(true);
    const users = ref([
      {
        id: "BB-231648",
        name: "Rohit Kumar",
        fname: "Sunny Sharma",
        upline: "Manish Goyal",
        phone: "7009123000",
        amount: "4000",
        date: "31 March 2024",
        clevel: "new",
      },
    ]);
    const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredUsers.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredUsers.value.length / itemsPerPage.value);
    });

    function changePage(page) {
      if (page < 1) {
        currentPage.value = 1;
      } else if (page > totalPages.value) {
        currentPage.value = totalPages.value;
      } else {
        currentPage.value = page;
      }
    }

    async function exportAsPdf() {
      const pdf = new jsPDF();

      const element = document.getElementById("pdf-table"); // Ensure your table has this ID
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("table.pdf");
    }

    const sortedUsers = computed(() => {
      return [...users.value].sort((a, b) => {
        if (sortKey.value) {
          const aValue = a[sortKey.value];
          const bValue = b[sortKey.value];

          if (sortAsc.value) {
            return aValue.localeCompare(bValue, undefined, { numeric: true });
          } else {
            return bValue.localeCompare(aValue, undefined, { numeric: true });
          }
        }
        return 0;
      });
    });

    const filteredUsers = computed(() => {
      return sortedUsers.value.filter((user) =>
        Object.values(user).some((field) =>
          field
            .toString()
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        )
      );
    });

    function sort(key) {
      if (sortKey.value === key) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortAsc.value = true;
        sortKey.value = key;
      }
    }

    return {
      users,
      searchQuery,
      filteredUsers,
      sort,
      exportAsPdf,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedUsers,
      changePage,
    };
  },
};
</script> -->
