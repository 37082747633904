<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <h2 class="h4 border-bottom pb-3" v-if="isAdmin">
        Genealogy / Members List :
        <span class="badge rounded-pill bg-primary total">
          {{ paginationData.total }}</span
        >
      </h2>
      <h2 class="h4 border-bottom pb-3" v-else>
        My Direct Sponser :
        <span class="badge rounded-pill bg-primary total">
          {{ paginationData.total }}</span
        >
      </h2>
      <div class="mt-4">
        {{ filteredList }}
        <div class="display-flex mb-2">
          <div class="flex-1">
            <ul class="level-tabs">
              <li>
                <span
                  class="badge rounded-pill active-level"
                  @click="filterByLevel(null)"
                  >All Members</span
                >
              </li>
              <li v-for="level in 4" :key="level" @click="filterByLevel(level)">
                <span class="badge rounded-pill bg-secondary active-level"
                  >Level {{ level }}</span
                >
              </li>
            </ul>
          </div>
          <div>
            <input
              type="search"
              class="search-form-control"
              placeholder="Search..."
              @input="debouncedSearch"
              v-model="searchQuery"
            />
            <button class="export-btn ms-3" @click="exportAsPdf">
              Export as Excel
              <span class="material-icons">print</span>
            </button>
          </div>
        </div>
        <div v-if="isLoading">Loading...</div>
        <div v-else>
          <div class="table-sort">
            <table class="table" id="pdf-table">
              <thead>
                <tr>
                  <th scope="col" @click="sortusersList('member_id')">
                    Member ID
                    <span class="material-icons">swap_vert</span>
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Father/Husband</th>
                  <th scope="col" v-if="isAdmin">Upline</th>
                  <th scope="col">Level</th>
                  <th scope="col">Mobile No.</th>
                  <th scope="col">
                    Date of Joining
                    <span class="material-icons">swap_vert</span>
                  </th>
                  <th scope="col" v-if="isAdmin">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="usersList.length == 0">
                  <td colspan="7" class="text-center">
                    No records match your criteria.
                  </td>
                </tr>

                <tr v-else v-for="user in usersList" :key="user.member_id">
                  <td @click="goToMemberDetails(user.member_id)">
                    {{ user.member_id }}
                  </td>
                  <td>
                    <div class="row">
                      <div class="profile-table">
                        <span>{{ user.name.substring(0, 1) }}</span>
                      </div>
                      <template v-if="isAdmin">
                        <abbr class="width-100-28" :title="user.password">{{
                          user.name
                        }}</abbr>
                      </template>
                      <template v-else>
                        {{ user.name }}
                      </template>
                    </div>
                  </td>
                  <td>{{ user.father_name }}</td>
                  <td v-if="isAdmin">{{ user.sponser_id }}</td>
                  <td>{{ user.level_position }}</td>
                  <td>{{ user.mobile_no }}</td>
                  <td>{{ formattedJoiningDate(user.date_of_joining) }}</td>

                  <td v-if="isAdmin">
                    <button
                      @click="goToMemberDetails(user.member_id)"
                      type="button"
                      class="export-btn ms-3"
                    >
                      Edit Details
                      <span class="material-icons"
                        >drive_file_rename_outline
                      </span>
                    </button>
                    <button
                      @click="unblockUser(user.member_id)"
                      class="export-btn ms-3"
                      v-if="user.blocked_status === 'Y'"
                    >
                      Unblock
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <PaginationPage
              :pagination="paginationData"
              @page-changed="handlePageChange"
            />
          </div>
        </div>
      </div>
    </div>

    <EditMember
      v-if="loadMember"
      :id="editMemberId"
      :basicDetails="editableMember"
      :bankDetails="editableBank"
      :nomineeDetails="editableNominee"
      :ISadmin="ISadmin"
      @on-close="closeEditMember" 
    />
  </DashboardLayout>
</template>

<script>
import _ from "lodash";

import DashboardLayout from "../dashboard/DashboardLayout.vue";
import axios from "axios";
import ApiList from "@/ApiList";
import PaginationPage from "../PaginationPage.vue";
import EditMember from "../members/EditMember.vue";

export default {
  path: "/member-details/:id",
  name: "MemberList",
  components: { DashboardLayout, PaginationPage, EditMember },
  mixins: [ApiList],
  data() {
    return {
      isLoading: true,
      loadMember: false,  
      ISadmin:true,
      isAdmin: false,
      editMemberId: "",
      isOpen: false,
      isblock: true,
      userData: {},
      usersList: [],
      searchQuery: "", // Initial search query
      sortKey: "credits",
      memberId: "",
      editableMember: {},
      editableBank: {},
      editableNominee: {},
      sortOrder: "asc",
      userId: "",
      level: "",
      role: false,
      paginationData: {},
      pageNo: 1,
      total: "",
      last_page: "",
      // backdrop: true,
      formData: {
        blocked_status: "",
      },
    };
  },
  created() {
    const tokenVal = localStorage.getItem("token");
    if (tokenVal) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${tokenVal}`;
    }
  },

  mounted() {
    const tokenVal = localStorage.getItem("token");
    if (tokenVal) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${tokenVal}`;
    }
    this.apis.member_id = localStorage.getItem("member_id");
    this.apis.member_name = localStorage.getItem("member_name");
    this.formData.member_id = this.apis.member_id;
    this.ISAdmin = this.checkIfAdmin();


    this.loadMemberList();
    this.onLoad();
  },

  methods: {
    checkIfAdmin() {
      return localStorage.getItem("user_role") === "admin";
    },
    closeEditMember() {
      this.loadMember = false;
      this.editMemberId = "";
    },
    formattedJoiningDate(dt) {
      if (!dt) return "";
      if (dt.length > 0) {
        const parts = dt.split("-");
        if (parts.length !== 3) return "Invalid date";

        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Format: dd/mm/yyyy
        return `${day}-${month}-${year}`;
      } else return "";
    },
    onLoad() {
      this.role = localStorage.getItem("role");
      if (this.role == "admin") {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
    debouncedSearch: _.debounce(function () {
      // Your search logic here (e.g., calling an API)
      this.searchValue();
      console.log("Searching for:", this.searchQuery);
      // Replace the console.log with your actual search implementation
    }, 1000), // Specify the debounce delay in milliseconds

    // member
    async searchValue() {
      const url = this.apis.apiUrl + "memberList";

      const response = await axios.get(url, {
        params: { search: this.searchQuery },
      });
      this.usersList = response.data.data;
    },
    async goToMemberDetails(memberId) {
      this.loadMember = true;
      this.editMemberId = memberId;
      this.isOpen = true;
    },
    handlePageChange(pageUrl) {
      const url = pageUrl;
      axios
        .get(url, {
          params: {
            // status: "unpaid",
          },
        })
        .then((response) => {
          if (response.data.error == "2") {
            alert(response.data.msg);
            this.logoutAll();
          }
          this.usersList = response.data.data;
          let pageData = response.data;
          this.paginationData.current_page = pageData.current_page;
          this.paginationData.links = pageData.links;
          this.paginationData.next_page_url = pageData.next_page_url;
          this.paginationData.path = pageData.path;
          this.paginationData.per_page = pageData.per_page;
          this.paginationData.prev_page_url = pageData.prev_page_url;
          this.paginationData.to = pageData.to;
          this.paginationData.total = pageData.total;
          // console.log("aa"+JSON.stringify(this.usersList))
        });
    },
    async unblockUser(memberId) {
      try {
        const formData = new FormData();
        formData.append("member_id", memberId);

        var url = this.apis.apiUrl + this.apis.unblockMember;
        var tokenVal = localStorage.getItem("token");

        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${tokenVal}`,
          },
        });

        this.successMsg = response.data.msg;
        this.validationErrors = {};
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.validationErrors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error);
        }
      }
    },

    logoutAll() {
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      this.$router.push("/");
    },
    async loadMemberList() {
      // alert("load data");
      try {
        this.isLoading = true;
        this.role = localStorage.getItem("role");
        if (this.role == "admin") {
          this.isadmin = true;
        } else {
          this.isadmin = false;
        }

        const url = this.apis.apiUrl + "memberList";
        axios
          .get(url, {
            params: {},
          })
          .then((response) => {
            if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
            let pageData = response.data;
            if (response.data && response.data.data) {
              // alert("true");
              this.usersList = response.data.data;
              this.last_page = pageData.last_page;
              // alert(this.last_page)
              this.paginationData.total = pageData.total;
              this.paginationData.current_page = pageData.current_page;
              this.paginationData.last_page = pageData.last_page;
              this.paginationData.links = pageData.links;
              this.paginationData.next_page_url = pageData.next_page_url;
              this.paginationData.path = pageData.path;
              this.paginationData.per_page = pageData.per_page;
              this.paginationData.prev_page_url = pageData.prev_page_url;
              this.paginationData.to = pageData.to;
              this.paginationData.total = pageData.total;
            } else {
              this.usersList = [];
            }
          });
      } catch (error) {
        console.error("Error loading member list:", error);
      } finally {
        this.isLoading = false;
      }
    },
    // goToPage(pageNumber) {
    //   this.currentPage = pageNumber;
    // },
    sortusersList(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortOrder = "asc";
        this.sortKey = key;
      }
      this.forceRefresh();
    },
    forceRefresh() {},

    // prevPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    // },
    // async exportAsPdf() {
    //   html2canvas(document.getElementById("pdf-table")).then((canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     const pdf = new jsPDF();
    //     const imgProps = pdf.getImageProperties(imgData);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    //     pdf.save("table.pdf");
    //   });
    //   const export_url = this.apis.apiUrl + "export";
    //   try {
    //     const response = await axios
    //       .get(export_url, {
    //         responseType: "blob",
    //       })
    //       .then((response) => {
    //         if (response.data.error == "2") {
    //           alert(response.data.msg);
    //           this.logoutAll();
    //         }
    //         const url2 = window.URL.createObjectURL(new Blob([response.data]));
    //         const link2 = document.createElement("a");
    //         link2.href = url2;
    //         link2.setAttribute("download", "filename.xlsx");
    //         document.body.appendChild(link2);
    //         link2.click();
    //       })
    //       .catch((error) => {
    //         console.error("Error downloading Excel file:", error);
    //       });
    //     this.usersList = response.data.data;
    //   } catch (error) {
    //     //alert("error")
    //     console.log("error");
    //   }
    //   // axios
    //   //   .get(export_url, {
    //   //     params: {
    //   //       // status: "paid",
    //   //       // income_type: "sponser",
    //   //     },
    //   //   })
    //   //   .then((response) => {
    //   //     // this.msg = response.data.msg;
    //   //     this.usersList = response.data.data;
    //   //   })
    //   //   .catch((error) => {
    //   //     console.error("Error while paying payments:", error);
    //   //   });
    // },
  },
};
</script>
