<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <h2 class="h4 border-bottom pb-3">
        Announcement List
         <!-- {{ announcementList }} -->
        <span class="badge rounded-pill bg-primary total">
          <!-- {{ paginationData.total }} -->
        </span>
      </h2>

      <div class="mt-4">
        <div class="display-flex mb-2">
          <div>
            <button class="export-btn ms-3" @click="exportAsPdf">
              Export as Excel
              <span class="material-icons">print</span>
            </button>
          </div>
        </div>
        <div v-if="isLoading">Loading...</div>
        <div v-else>
          <!-- Members Table -->
          <div class="table-sort">
            <table class="table" id="pdf-table">
              <thead>
                <tr>
                  <th scope="col">
                    Title <span class="material-icons">swap_vert</span>
                  </th>
                  <th scope="col">
                    Details<span class="material-icons">swap_vert</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in announcementList" :key="user.member_id">
                  <td>{{ user.title }}</td>
                  <td>{{ user.details }}</td>
               
                
                </tr>
              </tbody>
            </table>

           
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/components/dashboard/DashboardLayout.vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import ApiList from "@/ApiList";
// import PaginationPage from "../PaginationPage.vue";

export default {
  name: "AnnouncementsList",
  components: { DashboardLayout },
  mixins: [ApiList],
  data() {
    return {
      announcementList: {},
    };
  },
  // created() {
  //   const tokenVal = localStorage.getItem("token");
  //   if (tokenVal) {
  //     axios.defaults.headers.common["Authorization"] = ` ${tokenVal}`;
  //   }
  // },
  mounted() {
    const tokenVal = localStorage.getItem("token");
    if (tokenVal) {
      axios.defaults.headers.common["Authorization"] = ` ${tokenVal}`;
    }
    this.loadAnnouncementList();
  },

  methods: {
    loadAnnouncementList() {
      const url = this.apis.apiUrl + "announcementList";

      // alert(url)
      var tokenVal = localStorage.getItem("token");
      // alert(this.selecetdCategory)
      axios
        .get(url, {
          headers: {
            Authorization: `${tokenVal}`,
            'Content-Type': 'application/json' // Set the appropriate content type if needed
          },
        }) //Product/listCategories?token="+localStorage.token)
        .then((response) => {
          if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
          // console.log(response)

          this.announcementList = response.data;
          // this.apis.all_announcementList = this.announcementList;
        });
    },

    // Export the table as a PDF document
    exportAsPdf() {
      html2canvas(document.getElementById("pdf-table")).then((canvas) => {
     
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("table.pdf");
      });
    },
  },
};
</script>
