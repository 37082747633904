<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <div class="row">
        <div class="col-xl-7">
          <div class="profile-card">
            <h1>Welcome, {{ apis.member_name }} 👋 </h1>
            <!-- <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis
              veniam quaerat eos, neque asperiores molestiae dolor officia
              impedit eaque sequi.
            </p> -->
          </div>
          <div class="mt-4">
            <div class="head-section">
              <h3 class="h5">Member's Level</h3>
              <router-link to="/members-list">View All</router-link>
            </div>

            <div class="display-flex mb-2">
              <div class="flex-1">
                <ul class="level-tabs">
                  <li>
                    <span class="badge rounded-pill active-level">Level 1</span>
                  </li>
                  <li>
                    <span class="badge rounded-pill bg-secondary">Level 2</span>
                  </li>
                  <li>
                    <span class="badge rounded-pill bg-secondary">Level 3</span>
                  </li>
                  <li>
                    <span class="badge rounded-pill bg-secondary">Level 4</span>
                  </li>
                </ul>
              </div>
              <div>
                <input type="search" class="search-form-control" placeholder="Search..." @input="searchValue"
                  v-model="searchQuery" />
              </div>
            </div>
            <div class="table-sort">
              <table class="table" id="pdf-table">
                <thead>
                  <tr>
                    <th scope="col" @click="sort('name')">
                      Member ID
                      <span class="material-icons">swap_vert</span>
                    </th>
                    <th scope="col" @click="sort('name')">
                      Name
                      <span class="material-icons">swap_vert</span>
                    </th>
                    <th scope="col" @click="sort('phone')">
                      Mobile No <span class="material-icons"> swap_vert </span>
                    </th>
                    <th scope="col" @click="sort('doj')">
                      DOJ <span class="material-icons"> swap_vert </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in memberList.slice(0, 5)" :key="user.user_id">
                    <td>{{ user.member_id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.mobile_no }}</td>
                    <td>{{ formattedJoiningDate(user.date_of_joining) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="col-xl-11 mx-auto">
            <div class="dash-summery">
              <div class="card-items-bba mb-3 light-green">
                <div class="card-details">
                  <span>{{ userData.closing_joining }}</span>
                  <h4>Closing joining</h4>
                </div>
              </div>
              <div class="card-items-bba mb-3 peel-light">
                <div class="card-details">
                  <span>{{ userData.total_member }}</span>
                  <h4>Total Members</h4>
                </div>
              </div>
              <div class="card-items-bba trust-light">
                <div class="card-details">
                  <span>{{ userData.paid_payments }}</span>
                  <h4>Paid Payments</h4>
                </div>
              </div>
              <div class="card-items-bba red-light">
                <div class="card-details">
                  <span>{{ userData.tds }}</span>
                  <h4>TDS</h4>
                </div>
              </div>
            </div>
          </div>
          <swiper :options="swiperOptions">
            <swiper-slide v-for="slide in slides" :key="slide.id">
              {{ slide.content }}
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>

import axios from "axios";
import DashboardLayout from "./DashboardLayout.vue";
import ApiList from "@/ApiList";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

// import { ref, computed } from 'vue';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
export default {
  name: "AdminDashboard",
  components: { DashboardLayout, Swiper, SwiperSlide },
  mixins: [ApiList],
  data() {
    return {
      idleTimer: null,
      idleTimeout: 1 * 60 * 1000, // 15 minutes in milliseconds

      users: [],

      memberList: [],
      userData: {},

      slides: [
        { id: 1, content: "" },
        { id: 2, content: "" },
        { id: 3, content: "" },
      ],
      swiperOptions: {
        // Basic Swiper options (refer to Swiper documentation for details)
        slidesPerView: 1,
        spaceBetween: 30,
      },
    };
  },
  mounted() {
    // this.setupIdleTimer();
    // this.$el.addEventListener("mousemove", this.resetIdleTimer);
    // this.$el.addEventListener("keypress", this.resetIdleTimer);

    this.fetchUserList(); // Fetch user list on component mount
    this.loadDeshboardData();
    this.apis.member_id = localStorage.getItem("member_id");
    this.apis.member_name = localStorage.getItem("member_name");
  },
  methods: {
    formattedJoiningDate(dt) {
      // let dt = dtValue.toString();
      // alert(dt)
      // Assuming user.date_of_joining is in yyyy/mm/dd format
      if (!dt) return '';
      if (dt.length > 0) {
        const parts = dt.split('-');
        if (parts.length !== 3) return 'Invalid date';

        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Format: dd/mm/yyyy
        return `${day}-${month}-${year}`;
      }
      else return '';
    },
    async searchValue() {
      const url = this.apis.apiUrl + "memberList";

      const response = await axios.get(url, { params: { search: this.searchQuery } });

      this.memberList = response.data.data;
    }, // alert(this.usersList.length)
    logout() {
      // alert('bye')
      localStorage.clear();
      delete axios.defaults.headers.common['Authorization'];
      this.$router.push('/');
    },

    setupIdleTimer() {
      // alert("timer start")
      this.idleTimer = setTimeout(this.logout, this.idleTimeout);
    },
    resetIdleTimer() {
      // alert("reset timer")
      clearTimeout(this.idleTimer);
      this.setupIdleTimer();
    },
    beforeDestroy() {
      this.$el.removeEventListener("mousemove", this.resetIdleTimer);
      this.$el.removeEventListener("keypress", this.resetIdleTimer);
      clearTimeout(this.idleTimer);
    },
    fetchUserList() {
      // load datas
      const url = this.apis.apiUrl + "memberList";
      var tokenVal = localStorage.getItem("token");
      axios
        .get(url, {
          params: {
            // status: "unpaid",
          },
          headers: {
            Authorization: `${tokenVal}`,
          },
        })
        .then((response) => {
          // if (response.data.error == "2") {
          //     alert(response.data.msg);
          //     this.logoutAll();
          //   }
          this.memberList = response.data.data;
        });
    },
    loadDeshboardData() {
      const url = this.apis.apiUrl + "dashboardCount";
      axios
        .get(url, {
          params: {
            // status: "unpaid",
          },
        })
        .then((response) => {
          // if (response.data.error == "2") {
          //     alert(response.data.msg);
          //     this.logoutAll();
          //   }
          this.userData = response.data;
        });
    }
  },
  // setup() {

  //   const searchQuery = ref('');
  //   const sortKey = ref('');
  //   const sortAsc = ref(true);
  //   const users = ref([
  //     { id: 'BB-231648', name: 'Mohit', phone: '7009123000', doj: '20-01-2020' },
  //     { id: 'BB-231649', name: 'Dharm', phone: '9232382323', doj: '15-01-2019' },
  //     // Add more users as needed
  //   ]);

  //   async function exportAsPdf() {
  //     const pdf = new jsPDF();

  //     const element = document.getElementById('pdf-table'); // Ensure your table has this ID
  //     const canvas = await html2canvas(element);
  //     const imgData = canvas.toDataURL('image/png');

  //     const imgProps= pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('table.pdf');
  //   }

  //   const sortedUsers = computed(() => {
  //     return [...users.value].sort((a, b) => {
  //       if (sortKey.value) {
  //         const aValue = a[sortKey.value];
  //         const bValue = b[sortKey.value];

  //         if (sortAsc.value) {
  //           return aValue.localeCompare(bValue, undefined, { numeric: true });
  //         } else {
  //           return bValue.localeCompare(aValue, undefined, { numeric: true });
  //         }
  //       }
  //       return 0;
  //     });
  //   });

  //   const filteredUsers = computed(() => {
  //     return sortedUsers.value.filter((user) =>
  //       Object.values(user).some((field) =>
  //         field.toString().toLowerCase().includes(searchQuery.value.toLowerCase())
  //       )
  //     );
  //   });

  //   function sort(key) {
  //     if (sortKey.value === key) {
  //       sortAsc.value = !sortAsc.value;
  //     } else {
  //       sortAsc.value = true;
  //       sortKey.value = key;
  //     }
  //   }

  //   return { users, searchQuery, filteredUsers, sort , exportAsPdf };
  // },
};
</script>
