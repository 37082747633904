// import { ref } from 'vue';

const apis = {
  apiUrl: "https://new.bbahealthcare.com/api/",
  // apiUrl: "http://127.0.0.1:8000/api/",
  loginApi: "validateUser",
  saveMember: "saveMember",
  saveAnnouncement: "saveAnnouncement",
  fixedToken:
    "Bearer itHVHgvNXoxNnc9PSIsInZhbHVlIjoiU0pRbHVjSkJFcTNlOWtiOHdpdW5PLzJvaTNySytnTWd",
  member_id: "",
  member_name: "",
  memberList: "memberList",
  issueCredit: "issueCredit",
  unblockMember: "unblockMember",
  creditHistory: "creditHistory",
};

import axios from "axios";
// import ApiList from '@/ApiList';

export default {
  // mixins: [ApiList],

  data() {
    return {
      apis: apis,
      baseUrl: "https://new.bbahealthcare.com/api",
    };
  },
  methods: {
    logoutAll() {
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      this.$router.push("/");
    },
  },
};
