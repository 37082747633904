<template>
    <div class="width100">
        <div class="display-flex overlay-login">
            <div class="overlay-left col-xl-5 col-lg-6"></div>
            <div class="col-xl-7 col-lg-6 overlay-form">
                <!-- <img src="../../assets/images/SV_LOGO.png" class="cl-logo" alt="BBA Healthcare"> -->

                <div class="form">
                    <div v-if="err === 0" class="has-error alert alert-success">{{ msg }}</div>
                    <div v-if="err === 1" class="has-error alert alert-danger">{{ msg }}</div>
                    <div class="row">
                        <h1>Welcome Back! 👋</h1>
                        <p>Please login to your account.</p>
                        <div class="w100 mb-3 mt-3">
                            <label for="username" class="form-label">Username</label>
                            <input v-model.trim="username" type="text" class="form-control" id="username">
                        </div>
                        <div class="width100">
                            <label for="password" class="form-label">Password</label>
                            <input v-model.trim="password" type="password" class="form-control" id="password">
                        </div>
                        <div class="buttons">
                            <button class="login-form-btn" @click="checkUserValidate">Login</button>
                        </div>
                        <p class="mt-5 text-center"><router-link to="/"
                                class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover">Back
                                to Homepage</router-link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ApiList from "@/ApiList";
export default {
    data() {
        return {
            fixedToken: "Bearer itHVHgvNXoxNnc9PSIsInZhbHVlIjoiU0pRbHVjSkJFcTNlOWtiOHdpdW5PLzJvaTNySytnTWd",
            loading: false,
            user: '',
            pass: '',
            msg: '',
            err: -1, // 0 from no error; 1 for error
        }
    },
    mixins: [ApiList],
    created() {
        // console.log("i m create login")
        localStorage.clear();
    },
    mounted() {
        // console.log("i m mounted login")
    },
    methods: {

        async checkUserValidate(e) {
            var user = this.username;
            var pass = this.password;
            this.loading = true;
            // alert(user+"  "+pass)
            if (user != "" && pass != "") {
                var url = this.apis.apiUrl + this.apis.loginApi;
                // var url = "http://127.0.0.1:8000/api/validateUser";
                var tokenVal = this.fixedToken;
                // alert(this.selecetdCategory)
                await axios.get(url, {
                    params: {
                        username: user, password: pass
                    },
                    headers: {
                        Authorization: `${tokenVal}`,
                        // 'Content-Type': 'application/json' // Set the appropriate content type if needed
                    },
                }) //Product/listCategories?token="+localStorage.token)
                    .then((response) => {
                        if (response.data.error == "2") {
              alert(response.data.msg);
              this.logoutAll();
            }
                        console.log(response)
                        var res = response.data;
                        // alert(res.error)
                        if (res.error == "0") {
                            this.loading = false;
                            localStorage.setItem("token", res.token);
                            this.apis.member_id = res.data.member_id;
                            this.apis.member_name = res.data.name;
                            localStorage.setItem("member_id", res.data.member_id);
                            localStorage.setItem("member_name", res.data.name);
                            localStorage.setItem("role", res.data.role);
                            localStorage.setItem("date_of_joining", res.data.date_of_joining);
                            localStorage.setItem("mobile_no", res.data.mobile_no);
                            this.err = 0;
                            delete axios.defaults.headers.common['Authorization'];
                            if(res.data.role == "admin"){

                                this.$router.push('/dashboard')
                            }
                            else{
                                this.$router.push('/members-list')

                            }
                        }
                        else {
                            this.err = 1;
                            this.loading = false;
                            // this.msg = "Invalid Username or Password !";
                            this.msg = res.msg;
                            e.preventDefault();
                        }
                    });
            }
        },
    }
}
</script>