<template>
  <DashboardLayout>
    <div class="w-container mt-5">
      <div class="head-section border-bottom pb-3">
        <h3 class="h4">Change Password</h3>
       
      </div>
      <div class="mt-4">
        <div class="table-sort border-l">
          <!-- Success Message -->
          <div v-if="successMsg" class="alert alert-success">
            {{ successMsg }}
          </div>
          <!-- Error Message -->
          <div
            v-if="errorMsg"
            class="alert alert-danger"
            v-html="errorMsg"
          ></div>

          <div class="row">
            <div class="col-xl-20">
              <form class="form-style-1">
                <div class="row">
                  <div class="form-input-wrap">
                    <label for="current_password" class="form-label"
                      >Old Password <span>*</span></label
                    >
                    <input type="text" class="form-control" id="current_password" 
                    v-model.trim="formData.current_password" required/>
                  </div>
                  <div class="form-input-wrap">
                    <label for="new_password" class="form-label"
                      >New Password <span>*</span></label
                    >
                    <input type="text" class="form-control" 
                    id="new_password" 
                    v-model.trim="formData.new_password" required/>
                  </div>
                  <div class="form-input-wrap">
                    <label for="confirm_password" class="form-label"
                      >Confirm Password <span>*</span></label
                    >
                    <input type="text" class="form-control" id="confirm_password" 
                    v-model.trim="formData.confirm_password" required/>
                  </div>
                </div>
                <div class="mt-3">
                  <div>
                    <input
                      type="button"
                      value="Change Password"
                      class="p-btn"
                      @click="saveData"
                    />

                    <input
                      type="button"
                      value="Cancel"
                      class="s-btn ms-3"
                      @click="clearFormData"
                    />
                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import ApiList from "@/ApiList";
import axios from "axios";
export default {
  name: "ChangePassword",
  components: { DashboardLayout },
  mixins: [ApiList],
  data() {
    return {
      isSaving: false,
      formData: {
        sponsor_id: "",
        name: "",
        father_name: "",
        address: "",
        pan_card: "",
        aadhar_card: "",
        mobile_no: "",
      },
      successMsg: "",
      errorMsg: "",
      available_credits: 0,
    };
  },
  mounted() {
    this.apis.member_id = localStorage.getItem("member_id");
    // this.apis.member_name = localStorage.getItem("member_name");
    // this.formData.sponsor_id = this.apis.member_id;
    // this.loadCredits();
  },
  methods:{
    async saveData() {
      try {
        this.isSaving = true;
        this.successMsg = "";
        this.errorMsg = "";
        const formData = new FormData();
        // formData.append("sponsor_id", this.formData.sponsor_id);
        formData.append("current_password", this.formData.current_password);
        formData.append("confirm_password", this.formData.confirm_password);
        formData.append("new_password", this.formData.new_password);
      
        var url = this.apis.apiUrl + "changePassword";
        var tokenVal = localStorage.getItem("token");

        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `${tokenVal}`,
          },
        });
        this.successMsg = response.data.msg;
        this.isSaving = false;
        this.clearFormData();
      }
      catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          this.errorMsg = "<ul class='p-2'>" + Object.values(errors).flat().map(error => `<li>${error}</li>`).join("") + "</ul>";
        } else {
          this.errorMsg = "An error occurred while saving the member details.";
        }
        console.error(error);
      }

    },
    clearFormData() {
      // Reset form data
      this.formData = {
        sponsor_id: this.apis.member_id,
        current_password: "",
        new_password: "",
        confirm_password: "",
        
      };
    },
  }
};
</script>
