<template>
  <DashboardLayout>
    <div class="w-container mt-4">
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
          <symbol
            id="check-circle-fill"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
            />
          </symbol>
          <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
            <path
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </symbol>
          <symbol
            id="exclamation-triangle-fill"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </symbol>
        </svg>
        <svg
          class="bi flex-shrink-0 me-2"
          width="24"
          height="24"
          role="img"
          aria-label="Danger:"
        >
          <use xlink:href="#exclamation-triangle-fill" />
        </svg>
        <div>
          Please add
          <a
            class="alert-link"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            >bank details</a
          >!
        </div>
      </div>
    </div>

    <div class="w-container mt-3">
      <div class="head-section border-bottom pb-3">
        <h2 class="h4">
          {{ memberDetails?.name || "NA" }} -
          {{ memberDetails?.member_id || "NA" }}
          <!-- <span class="badge h6 rounded-pill bg-primary fs-6">Level 1</span> -->
        </h2>

        <div>
          <button
            class="export-btn ms-3"
            @click="getMemberDetails(memberDetails.member_id)"
          >
            Edit Details
            <span class="material-icons"> drive_file_rename_outline </span>
          </button>
          <!-- <button class="export-btn ms-3" @click="exportAsPdf">Export as Excel <span class="material-icons">
            print
            </span></button> -->
        </div>
      </div>
      <ul class="member-details">
        <li>
          Father/Husband Name
          <span>{{ memberDetails?.father_name || "NA" }}</span>
        </li>

        <li>
          Mobile No. <span>{{ memberDetails?.mobile_no || "NA" }}</span>
        </li>
        <!-- <li>Father Name <span>Bara Singh</span></li> -->
        <li>
          Date of joining
          <span>{{ memberDetails?.date_of_joining || "-" }}</span>
        </li>
        <li>
          Date of birth <span>{{ memberDetails?.dateofbirth || "-" }}</span>
        </li>
        <li>
          Address <span>{{ memberDetails?.address || "NA" }}</span>
        </li>
        <li>
          PAN Card No.<span>{{ memberDetails?.pan_card || "NA" }}</span>
        </li>
        <li>
          Aadhar Card No.
          <span>{{ memberDetails?.aadhar_card || "NA" }}</span>
        </li>
      </ul>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button collapsed h5"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Bank Details
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <ul class="member-details">
              <li>
                Account Holder Name
                <span>{{ BankDetails?.bank_holder_name || "-" }}</span>
              </li>
              <li>
                Bank Name <span>{{ BankDetails?.bank_name || "-" }}</span>
              </li>
              <li>
                Account Number <span>{{ BankDetails?.account_no || "-" }}</span>
              </li>
              <li>
                IFSC Code <span>{{ BankDetails?.ifsc_code || "-" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button
            class="accordion-button collapsed h5"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            Nominee Details
          </button>
        </h2>
        <div
          id="collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <ul class="member-details">
              <li>
                Nominee Name <span>{{ NomineeDetails?.name || "-" }}</span>
              </li>
              <li>
                Nominee Relationship<span>{{
                  NomineeDetails?.relation || "-"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button
            class="accordion-button collapsed h5"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Payment History
          </button>
        </h2>
        <div
          id="collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <p>Under Maintenance</p>
          </div>
        </div>
      </div>
    </div>
    <EditMember
      v-if="loadMember"
      :id="editMemberId"
      :basicDetails="memberDetails"
      :bankDetails="BankDetails"
      :nomineeDetails="NomineeDetails"
      @on-close="closeEditMember"
    />
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "../dashboard/DashboardLayout.vue";
import EditMember from "../members/EditMember.vue";
import ApiList from "@/ApiList";
import axios from "axios";

export default {
  name: "MemberDetails",
  components: { DashboardLayout, EditMember },
  mixins: [ApiList],
  data() {
    return {
      loadMember: false,
      isOpen: false,
      memberId: "",
      editMemberId: "",
      memberDetails: {},
      BankDetails: {},
      NomineeDetails: {},
      count: 0,
    };
  },
  mounted() {
    //  alert("loadMember")
    this.memberId = localStorage.getItem("member_id");
    this.ISAdmin = this.checkIfAdmin();
    this.getMemberDetails();
  },
  methods: {
    checkIfAdmin() {
      return localStorage.getItem("user_role") === "admin";
    },
    closeEditMember() {
      this.loadMember = false;
      this.editMemberId = "";
    },

    async getMemberDetails(memberId) {
      //close toggle
      this.count = 0;
      this.loadMember = false;
      this.editMemberId = memberId;
      this.isOpen = true;

      const url = this.apis.apiUrl + "member-details";
      axios
        .get(url, {
          params: { memberid: this.memberId },
        })
        .then((response) => {
          if (response.data.error == "2") {
            alert(response.data.msg);
            this.logoutAll();
          }
          console.log(response);
          this.memberDetails = response.data;
          this.count++;
        });

      //member-bank-details API
      // this.memberId = localStorage.getItem("member_id");
      // alert(this.memberId)

      const url2 = this.apis.apiUrl + "member-bank-details";
      const response2 = await axios.get(url2, {
        params: { memberid: this.memberId },
      });
      this.BankDetails = response2.data;
      this.count++;
      console.log(response2);

      //member-nominee-details API
      const url3 = this.apis.apiUrl + "member-nominee-details";
      const response3 = await axios.get(url3, {
        params: { memberid: this.memberId },
      });
      // alert(response3)
      this.NomineeDetails = response3.data;
      this.count++;
      console.log(response3);
      if (this.count == 3) {
        this.loadMember = true;
      }
    },
  },
};
</script>
