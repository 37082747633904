<template>
  <!-- {{ pagination }} -->
  <nav aria-label="Page navigation">
    <div class="w-50 d-flex justify-content-between">
      Page No: {{ pagination.current_page }} / {{ pagination.last_page }}
      <span>
          <input type="text" placeholder="Page No" v-model="page_no" />
          <input type="button" value="Go" @click.prevent="navigateTo"/>
        </span>
    </div>
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
        <a
          class="page-link"
          href="#"
          @click.prevent="navigateTo(pagination.prev_page_url)"
        >
          Back
        </a>
      </li>

      <!-- <li class="page-item" v-for="(link, index) in pagination.links,maxVisibleLinks" :key="index" :class="{ active: link.active }"> -->
      <!-- <li class="page-item" v-for="(link, index) in allLinks" :key="index" :class="{ active: link.active }">
        <a class="page-link" href="#" @click="navigateTo(link.url)" v-if="link.url">{{ link.label }}</a>
        <span class="page-link" v-else>{{ link.label }}</span>
      </li> -->
     
      <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
        <a
          class="page-link"
          href="#"
          @click.prevent="navigateTo(pagination.next_page_url)"
        >
          Next
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import ApiList from '@/ApiList';
export default {
  name: "PaginationPage",
  mixins:[ApiList],
  props: {
    pagination: Object,
  },
  data() {
    return {
      page_no:"",
      maxVisibleLinks: 5, // Default value for maximum visible links
    };
  },

  computed: {
    visibleLinks() {
      if (!this.pagination || !this.pagination.links) {
        return []; // Return an empty array if pagination or pagination.links is undefined
      }

      // Calculate the start index of the visible links
      let startIndex = Math.max(
        0,
        this.pagination.current_page - Math.floor(this.maxVisibleLinks / 2)
      );
      // Calculate the end index of the visible links
      let endIndex = Math.min(
        startIndex + this.maxVisibleLinks - 1,
        this.pagination.last_page
      );

      // Ensure that we always show `maxVisibleLinks` links
      if (endIndex - startIndex < this.maxVisibleLinks - 1) {
        startIndex = Math.max(0, endIndex - (this.maxVisibleLinks - 1));
      }
      // Slice the links array to get only the visible links
      return this.pagination.links.slice(startIndex, endIndex + 1);
    },
    allLinks() {
      if (!this.pagination || !this.pagination.links) {
        return []; // Return an empty array if pagination or pagination.links is undefined
      }

      // Combine visible links with pagination links
      return [...this.visibleLinks, ...this.pagination.links];
    },
  },
  methods: {
    navigateTo(url) {
      // let pageNo = url.split("=")[1];
      // alert(this.page_no )
      if(this.page_no !='')
      {
        if(this.page_no>= 1 && this.page_no <= this.pagination.last_page)
        { 
            let url2 = this.apis.apiUrl + "memberList?page_no="+this.page_no;
          // alert(url2)
            this.$emit("page-changed", url2);
        }
        else
        {
          alert(`Please Enter Page No Between 1 - ${this.pagination.last_page}`)
        }
      }
      else{
        this.$emit("page-changed", url);
      }
    },
    
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  list-style: none;
  margin-right: 5px;
}

.page-link {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.page-link:hover {
  background-color: #f0f0f0;
}

.page-link.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.page-link.disabled {
  pointer-events: none;
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}
</style>
