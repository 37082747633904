<template>
  <div class="w-100 bg-white px-4 py-3">
    <div class="d-flex align-items-center">
      <div class="lf-t-header d-flex align-items-center">
        <span class="badge bg-light text-dark"><span class="material-icons">
            reorder
          </span></span>
        &nbsp;&nbsp;
        <router-link to="/register-new-member" class="badge rounded-pill bba-bg-primary p-2">Register New
          Member</router-link>

      </div>

      <div class="right-header">
        <ul class="rt-links">
          <li class="nav-item dropdown">
            <a class="dropdown-toggle btn-profile text-link" id="navbarScrollingDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <div class="profile">
                <span>{{ apis.member_name.substring(0, 1) }}</span>
              </div> {{ apis.member_name }} ({{ apis.member_id }})

            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
              <!-- <li><router-link to="/member-details" class="dropdown-item">My Account</router-link></li> -->
              <li><router-link to="change-password" class="dropdown-item" href="#">Change Password</router-link></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><button class="dropdown-item" @click="logout">Log Out</button></li>
            </ul>
          </li>

        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ApiList from '@/ApiList';
export default {
  name: 'TopHeader',
  mixins: [ApiList],
  data() {
    return {
      userName: '',
      user_id: '',
    }
  },
  created(){
    if (!localStorage .getItem("member_id")){
      this.$router.push('/');
    }
  },
  mounted() {
    // alert("start")

    let name  = localStorage.getItem("member_name");
    this.apis.member_name=name;
    let id = localStorage.getItem("member_id");
    this.apis.member_id = id;
   
    this.onLoad();
    // alert(this.apis.member_name)
  },
  methods: {
    onLoad() {
      this.user_id = localStorage.getItem("member_id");
    },
    logout() {
      // alert('bye')
      localStorage.clear();
      delete axios.defaults.headers.common['Authorization'];
      this.$router.push('/');
    },

  }
}
</script>